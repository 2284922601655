@font-face {
  font-family: Montserrat;
  src: url(/assets/fonts/Montserrat-Black.otf);
  font-weight: 800;
}

@font-face {
  font-family: Montserrat;
  src: url(/assets/fonts/Montserrat-ExtraBold.otf);
  font-weight: 700;
}

@font-face {
  font-family: Montserrat;
  src: url(/assets/fonts/Montserrat-Bold.otf);
  font-weight: 600;
}

@font-face {
  font-family: Montserrat;
  src: url(/assets/fonts/Montserrat-SemiBold.ttf);
  font-weight: 500;
}

@font-face {
  font-family: Montserrat;
  src: url(/assets/fonts/Montserrat-Regular.ttf);
  font-weight: 400;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: Montserrat, sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased !important;
  overflow-x: hidden !important;
}

body iframe {
  display: none !important;
}

body .app-layout {
  display: inline-block;
  width: 100%;
  height: 100vh;
  background: #f8f8f8;
}

a:focus,
input:focus,
:focus,
textarea:focus {
  outline: 0px !important;
  box-shadow: 0px 0px 0px !important;
}

.front-wrapper {
  width: 100%;
  float: left;
  background: #f8f8f8;
  min-height: 100vh;
}

.front-left {
  float: left;
  width: 100%;
  padding: 75px;
  position: relative;
  z-index: 9;
}

.log-header {
  width: 100%;
  float: left;
  margin-top: 100px;
}

.log-header h1 {
  font-size: 40px;
  color: #121d55;
  font-weight: 500;
}

/* Replace 'your-css-file.css' with the path to your CSS file or add this directly to your HTML file using <style> tag */
/* For the blue button */
.blue-button {
  background-color: #12cae6;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

/* For the DFDFDF colored button */
.dfdfdf-button {
  background-color: #dfdfdf;
  color: black;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.log-header h3 {
  font-size: 26px;
  color: #12cae6;
  font-weight: 500;
}

.error-message-text {
  margin-left: 8px;
  color: red;
  font-size: 14px;
}

.log-form {
  width: 100%;
  max-width: 450px;
  float: left;
  margin-top: 70px;
}

.log-form h4 {
  font-size: 22px;
  font-weight: 600;
  color: #121d55;
}

.input-div {
  width: 100%;
  float: left;
  position: relative;
  margin-top: 20px;
}

.input-div span {
  font-size: 12px;
  color: #fa7a00;
  font-weight: 500;
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: 13px;
  left: 12px;
  transition: all ease 0.2s;
}

.input-div.open span {
  opacity: 1;
  z-index: 1;
  top: -10px;
  transition: all ease 0.2s;
}

.input-div a,
.input-div p {
  position: absolute;
  right: 12px;
  cursor: pointer;
  top: 5px;
  z-index: 2;
}

.custom-input {
  height: 44px;
  font-weight: 500;
  color: #333;
  font-size: 14px;
  background: white;
  padding: 12px;
  border: 0px;
  border-radius: 10px;
}

.forgot-pass {
  color: #fa7a00 !important;
  font-weight: 500;
  float: right;
  margin-top: 10px;
  cursor: pointer;
  font-size: 12px;
}

.log-btn {
  height: 44px;
  text-align: center;
  border-radius: 10px;
  width: 100%;
  background: #12cae6;
  color: white;
  border: 0px;
}

.dnthaveacc {
  color: #121d55;
  font-size: 14px;
  width: 100%;
  float: left;
  margin-top: 100px;
}

.dnthaveacc a {
  font-weight: 500;
  color: #121d55;
}

.eye {
  display: none;
}

.open .eye {
  display: inline-block;
}

.big-bg {
  position: fixed;
  right: 0px;
  bottom: -10px;
  height: 90vh;
  object-fit: contain;
}
.plusworking {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px !important;
}
.plusworking .plusiconbar.disable-section input[type='file'] {
  display: none !important;
}
.plusworking .plusiconbar {
  position: relative;
  overflow: hidden;
}

.plusworking .plusiconbar input[type='file'] {
  top: 0;
  width: auto;
  cursor: pointer;
}

.plusworking .plusiconbar img {
  position: relative !important;
  top: inherit !important;
  right: inherit !important;
}
.disable-btn {
  background: #dfdfdf;
}

.disable-section {
  background: #eee9e9 !important;
  cursor: not-allowed !important;
  border-radius: 10px;
  /* background: #dfdfdf !important; */
  /* opacity: 0.9 !important; */
}

/*Step wrapper*/

.step-wrapper {
  width: 100%;
  float: left;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.95);
  z-index: 9999999;
  /* background: url("/assets/img/blurbg.png") center center no-repeat; */
  background-size: cover;
  padding: 10px;
}

.step-outter {
  /* min-height: 610px; */
  max-width: 480px;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  background: #f8f8f8;
  padding-bottom: 5px;
  /* box-shadow: 5px 5px 10px rgb(0 0 0 / 0.16); */
  /* box-shadow: 0 0 15px 10px rgba(134, 134, 134, 0.5); */
  box-shadow: 0px 1px 12px 0px #00000029;
}

.step-top {
  width: 100%;
  float: left;
  padding: 30px;
  background: white;
}

.step-nav {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
}

.step-progress {
  width: 100%;
  float: left;
  margin-top: 35px;
}

.step-p-text {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.step-p-text h4 {
  color: #121d55;
  font-size: 22px;
  font-weight: 600;
}

.step-p-text span {
  color: #fa7a00;
  font-size: 12px;
  font-weight: 400;
}

.progress {
  width: 100%;
  float: left;
  border-radius: 27px;
  margin-top: 10px;
  height: 5px;
  background-color: #cff5fa;
}

.progress-bar {
  background-color: #12cae6;
  border-radius: 20px;
}

.step-bottom {
  width: 100%;
  float: left;
  padding: 0px 15px;
}

.step-1 {
  width: 100%;
  float: left;
}

.placeholder-image {
  width: 100%;
  float: left;
  padding: 50px 0px;
  text-align: center;
}

.placeholder-image img {
  border-radius: 32px;
  width: 115px;
}

.step-form {
  max-width: 310px;
  margin: 0 auto;
}

.add-image {
  position: absolute;
  opacity: 0;
  z-index: 999;
}

.patient-detail-edit {
  cursor: pointer;
}

.step-btn {
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100%;
  float: left;
}

.step-2-form {
  padding-top: 10px;
  width: 100%;
  float: left;
}

.step-bottom .forgot-pass {
  margin-bottom: -30px;
}

.three-steps {
  width: 100%;
  float: left;
}

.thanks-step {
  width: 100%;
  float: left;
}

.thanks-step {
  max-width: 310px;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  background: #fff;
  padding: 20px 15px;
  padding-bottom: 0px;
  /* box-shadow: 5px 5px 10px rgb(0 0 0 / 0.16); */
  box-shadow: 0 0 15px 10px rgba(134, 134, 134, 0.5);
}

.thanks-step h4 {
  color: #12cae6;
  font-weight: 600;
  font-size: 20px;
}

.thanks-step p {
  font-size: 14px;
  color: #121d55;
  margin: 0px;
}

.autocomplete-dropdown-container {
  position: absolute;
  z-index: 99999;
  box-shadow: 0px 0 5px 0 #eee;
  background: #fff;
  width: 100%;
}

.container-shadow {
  box-shadow: 0 0 2px 0 rgba(134, 134, 134, 0.5);
}

.suggestion-item,
.suggestion-item--active {
  padding-bottom: 20px;
}

.suggestion-item,
.suggestion-item--active {
  padding: 10px 10px 0;
}

.suggestion-item p:first-child,
.suggestion-item--active p:first-child {
  font-size: 14px;
  color: #000;
  margin: 0;
  line-height: 1;
}

.suggestion-item p:last-child,
.suggestion-item--active p:last-child {
  font-size: 13px;
  color: #12cae6;
  margin-bottom: 10px;
}

.suggestion-item:hover,
.suggestion-item--active:hover {
  background: #dfdfdf !important;
}

.suggestion-item:hover p:last-child,
.suggestion-item--active:hover p:last-child {
  color: #fff;
}

.suggestion-item:last-child label {
  border-bottom: solid 0px #ddd !important;
}

.autocomplete-dropdown-container label {
  border-bottom: solid 1px #ddd !important;
  /* padding: 6px 10px !important; */
  display: inline-block;
  color: #000;
  font-size: 11px;
  margin: 0;
  width: 100%;
}

.leftbar-menu {
  position: fixed;
  width: 100px;
  left: 0;
  top: 0;
  height: 100%;
  box-shadow: 5px 0px 15px #0000001f;
  background: #fff;
  z-index: 9;
}

.dashboard-logo {
  text-align: center;
  margin-top: 30px;
  position: relative;
}

.dashboard-inner-sec {
  width: 91%;
  padding: 30px;
  margin-left: 130px;
  margin-top: 30px;
  background: #fff;
  box-shadow: 0px 1px 10px #00000029;
  min-height: 90vh;
  max-width: calc(100% - 150px);
}

.dashboard {
  display: inline-block;
  width: 100%;
  background: #f8f8f8;
}

.dashboard-inner-head h2 {
  display: inline-block;
  font-size: 26px;
  font-family: Montserrat;
  font-weight: 400;
}

.dashboard-head-btn {
  float: right;
}

.dashboard-head-btn a {
  background: #121d55;
  color: #fff !important;
  padding: 10px 15px 10px 20px;
  display: inline-block;
  font-weight: 100;
  border-radius: 10px;
  text-decoration: none !important;
}

.dashboard-head-btn a:hover {
  background: #182b8c;
}

.leftbar-bottom {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
}

.leftbar-bottom ul {
  padding: 0;
  text-align: center;
  font-size: 12px;
  font-family: Montserrat;
  border-bottom: 1px solid #ddd;
  margin: 0 10px 20px;
}

.leftbar-bottom ul a {
  display: inline-block;
  width: 100%;
  padding: 15px 0;
  color: #121d55;
  text-decoration: none;
}

.leftbar-bottom ul a span {
  display: block;
  width: 40px;
  height: 40px;
  border: 2px solid;
  border-radius: 10px;
  margin: 0 auto 10px;
}

.leftbar-bottom ul a img.menu-white {
  display: none;
}

.leftbar-bottom ul a.menu-active img.menu-white {
  display: block;
}

.leftbar-bottom ul a.menu-active img {
  display: none;
}

.leftbar-bottom ul a.menu-active span {
  background: #121d55;
}

.leftbar-bottom ul a img {
  display: block;
  margin: 0 auto 5px;
  width: 25px;
  padding-top: 6px;
}

.mobile-menu .leftbar-bottom ul a.menu-active img {
  display: none;
}

.mobile-menu .leftbar-bottom ul a img.menu-white {
  display: none;
}

.mobile-menu .leftbar-bottom ul a.menu-active img.menu-white {
  display: block;
}

.account-menu {
  text-align: center;
}

.dashboard-head-btn a img {
  margin-left: 20px;
  width: 17px;
  display: inline-block;
  margin-top: -3px;
}

.dashboard-head-filter {
  float: right;
  width: 200px;
  margin-right: 20px;
}

.dashboard-head-filter select {
  background: #f8f8f8;
  border-width: 0;
  border-radius: 10px;
  padding: 10px 10px;
  height: auto;
  font-size: 14px;
}

.dashboard-head-search {
  float: right;
  margin-right: 20px;
  position: relative;
}

.dashboard-head-search input {
  background: #f8f8f8 !important;
  border-width: 0;
  border-radius: 10px;
  padding: 10px 10px;
  height: auto;
  font-size: 14px;
  width: 300px;
}

.dashboard-head-filter .select-box {
  background: #f8f8f8 !important;
  padding: 10px;
  font-size: 14px;
  border-radius: 10px;
}

.dashboard-head-filter .select-box-list ul li {
  color: #121d55;
}

.dashboard-head-search img {
  position: absolute;
  right: 8px;
  top: 12px;
}

.dashboard-head-right {
  display: inline-block;
  width: 80%;
}

.patient-table .table thead th {
  color: #fa7a00;
  font-weight: normal;
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 0px solid #dee2e6 !important;
}

.patient-table .table tbody tr:first-child td {
  border-top: 0px solid #dee2e6;
}

.dashboard-inner-head {
  margin-bottom: 15px;
}

.patient-table .table td {
  vertical-align: middle;
}

td.pateint-name {
  font-weight: bold;
}

td.pateint-name img {
  margin-right: 20px;
}

td.pateint-contact a,
td.pateint-contact span {
  display: block;
  color: #121d55;
}

td.patient-compliance {
  font-size: 23px;
  font-weight: bold;
}

span.compliance-up {
  color: #4fc931;
  font-size: 14px;
  font-weight: normal;
  margin-top: 5px;
  margin-left: 15px;
}

span.compliance-up span {
  display: inline-block;
  border-bottom: 11px solid #4fc931;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

span.compliance-down {
  color: red;
  font-size: 14px;
  font-weight: normal;
  margin-top: 5px;
  margin-left: 15px;
}

span.compliance-down span {
  display: inline-block;
  border-top: 11px solid red;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

td.patient-compliance > span {
  float: left;
}

td.team-mem-images img {
  margin-right: -10px;
  float: right;
}

.team-images-list {
  display: inline-block;
  margin-left: 10px;
}

span.compliance-down {
  color: #ef0000;
  font-size: 14px;
  font-weight: normal;
  margin-top: 5px;
  margin-left: 15px;
}

span.compliance-down span {
  display: inline-block;
  border-top: 11px solid #ef0000;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

.add-patent-pop {
  /* background: #ffffff 0% 0% no-repeat padding-box; */
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  width: 100%;
  position: fixed;
  top: 0;
  left: 100px;
  background: rgba(255, 255, 255, 0.95);
  /* background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box; */
  height: 100%;
  /* display: none; */
  z-index: 99999999;
}

.add-patent-popinner {
  display: table;
  width: 100%;
  height: 100%;
  padding: 100px 0;
}

.add-patent-box {
  display: table-cell;
  vertical-align: middle;
}

.patent-box-inner {
  width: 1200px;
  margin: 0 auto;
  position: relative;
}

.patient-manual-entry {
  background: #f8f8f8;
  border-radius: 20px;
  box-shadow: 3px 4px 16px 0px #00000029;
}

.manual-entry-head h2 {
  font-size: 22px;
  min-height: 53px;
  margin: 0;
}

.manual-entry-head {
  padding: 30px 20px 20px;
  background: #fff;
  border-radius: 20px 20px 0 0;
}

.manual-entry-inner {
  padding: 20px;
}

.manual-entry-inner img {
  margin: 70px auto;
  display: inherit;
}

.manual-entry-inner p {
  font-size: 14px;
}

.manual-entry-inner .step-btn {
  float: none;
}

.patient-pop-close {
  position: absolute;
  right: 0;
  top: -68px;
  background: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}

.care-plan-form {
  width: 60%;
  margin: 60px auto 70px;
}

.care-plan-form input.form-control {
  border-width: 0;
  font-size: 14px;
}

.care-plan-form label {
  font-size: 12px;
  font-weight: normal;
}

.code-input input.form-control {
  width: 15%;
  display: inline-block;
  margin-right: 2%;
  font-size: 22px;
  text-transform: uppercase;
  text-align: center;
}

.code-input input.form-control:last-child {
  margin-right: 0;
}

a.whats-this {
  float: right;
  color: #fa7a00;
  font-size: 10px;
}

.step-wrapper.common-pop {
  position: fixed;
  top: 0;
  left: 100px;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-position: -2px -3px;
  z-index: 999999;
}

.patient-pop-outer {
  display: table;
  width: 100%;
  height: 100%;
}

.patient-pop-box {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  padding: 50px 0;
}

.patient-pop-box .step-outter {
  /* min-height: 610px; */
  max-width: 480px;
  width: 480px;
  border-radius: 20px;
  overflow: hidden;
  background: #f8f8f8;
  padding-bottom: 5px;
  /* box-shadow: 5px 5px 10px rgb(0 0 0 / 0.16); */
  /* box-shadow: 0 0 15px 10px rgba(134, 134, 134, 0.5); */
  margin: 0 auto;
  box-shadow: 0px 1px 12px 0px #00000029;
}

.patient-pop-box .step-bottom .forgot-pass {
  margin-bottom: 0;
}

.patient-pop-box .step-progress {
  margin: 0;
  font-weight: lighter;
}

.pop-close-cross {
  cursor: pointer;
}

.patient-pop-box .custom-input {
  font-weight: lighter;
  border-width: 0;
  box-shadow: unset;
}

button.log-btn.step-btn.disable-btn {
  cursor: not-allowed;
}

.select-box {
  background: #fff;
  padding: 10px;
  font-size: 14px;
  border-radius: 10px;
}

.custom-select-input {
  position: relative;
}

.select-box-list {
  background: #fff;
  position: absolute;
  top: 0;
  z-index: 99;
  width: 100%;
  border-radius: 10px;
  display: none;
  box-shadow: 0px 3px 6px #0000001a;
}

.select-box-list ul {
  list-style: none;
  padding: 0 10px;
  margin: 0;
}

.select-box-list ul li {
  padding: 10px 0;
  border-bottom: 1px solid #efefef;
  font-size: 14px;
  cursor: pointer;
}

.select-box-list ul li:first-child {
  color: #cecece;
}

.select-icon {
  z-index: 99999;
  position: absolute;
  width: 11px;
  right: 10px;
  top: 6px;
}

.select-icon img {
  max-width: 100%;
}

select.form-control {
  box-shadow: unset !important;
}

.step-wrapper.patient-success-pop {
  position: fixed;
  top: 0;
  left: 100px;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-position: -2px -3px;
  /* display: none; */
}

.step-wrapper.patient-success-pop .step-outter {
  max-width: 380px;
  width: 380px;
}

.success-patient-inner {
  display: inline-block;
  width: 100%;
}

.success-patient-inner img {
  margin: 50px auto 70px;
  display: block;
}

.success-patient-inner p {
  font-size: 14px;
  font-weight: lighter;
}

.step-wrapper.patient-success-pop .step-p-text {
  width: 100%;
  float: none;
  display: inline-block;
}

.step-wrapper.patient-success-pop .step-p-text h4 {
  width: 70%;
  float: left;
}

.step-wrapper.patient-success-pop .pop-close-cross {
  cursor: pointer;
  float: right;
}

a.log-btn.step-btn {
  padding: 10px;
  color: #fff;
  cursor: pointer;
}

.dashboard-inner-clinic {
  width: 91%;
  margin-left: 130px;
  margin-top: 30px;
  min-height: 90vh;
  margin-bottom: 30px;
  max-width: calc(100% - 150px);
}

.clinic-detail-left {
  width: 39%;
  margin-right: 1%;
  float: left;
}

.clinic-detail-box {
  box-shadow: 0px 1px 10px #00000029;
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 20px;
  position: relative;
}

.clinic-detail-head h3 {
  font-size: 26px;
  font-weight: 500;
  float: left;
}

.clinic-detail-head h3 a {
  color: #fa7a00;
  font-size: 12px;
  text-decoration: underline;
  margin-left: 10px;
}

.clinic-detail-inner {
  margin-top: 30px;
}

.clinic-detail-inner label {
  color: #fa7a00;
  font-size: 12px;
}

.clinic-detail-inner p {
  font-size: 14px;
  margin-bottom: 20px;
  font-weight: lighter;
  color: #121d55;
}

.clinic-detail-right {
  width: 60%;
  float: right;
}

button.btn.sky-btn {
  background: #12cae6;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
}

button.btn.sky-btn:hover {
  background: #0bafc7;
}

.clinic-detail-head .btn.sky-btn {
  float: right;
}

.clinic-detail-head {
  display: inline-block;
  width: 100%;
}

.subscription-plan-name h2 {
  font-size: 26px;
  font-weight: lighter;
}

.subscription-plan-name p {
  color: #12cae6;
}

.subscription-table .table thead th {
  padding: 0;
  border-top: 0px solid #dee2e6;
  font-size: 12px;
  font-weight: lighter;
  color: #fa7a00;
  border-bottom: 0px solid #dee2e6 !important;
}

.subscription-table td {
  padding: 0;
  border-top: 0px solid #dee2e6;
  font-size: 14px;
  font-weight: lighter;
}

.subscription-table {
  padding: 40px 0;
  border-bottom: 1px solid #ddd;
  margin-bottom: 50px;
}

.clinic-administartor h4 {
  font-size: 14px;
  font-weight: 500;
}

.clinic-administartor h4 a.clinic-adminpop-btn {
  color: #121d55;
  float: left;
  font-size: 14px;
  text-decoration: none;
}

.clinic-administartor h4 span {
  background: #fa7a00;
  color: #fff;
  font-size: 9px;
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  margin-left: 3px;
}

.clinic-administartor h4 a {
  background: #fff;
  color: #fa7a00;
  font-size: 12px;
  float: right;
  text-decoration: underline;
}

.clinic-administartor {
  width: 260px;
  margin-top: 15px;
}

.clinic-administartor-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  margin-top: 15px;
  padding: 10px;
  display: inline-block;
  width: 100%;
  position: relative;
}

.clinic-administartor-box img {
  float: left;
  margin-right: 10px;
}

.administartor-box-name span {
  font-size: 12px;
  font-weight: lighter;
  color: #12cae6;
}

.administartor-box-name p {
  margin: 6px 0 0;
  line-height: 1;
  font-weight: lighter;
}

.administartor-box-name {
  margin-left: 30px;
}

.clinicians-heading h4 {
  font-size: 14px;
  font-weight: 500;
}

.clinicians-heading h4 a {
  background: #fff;
  color: #fa7a00;
  font-size: 12px;
  float: right;
  text-decoration: underline;
}

.clinicians {
  margin-top: 50px;
}

.clinicians-heading {
  width: 260px;
}

.clinicians-list {
  display: inline-block;
  width: 565px;
}

.clinicians-list .clinic-administartor-box {
  width: 260px;
  float: left;
  margin-top: 0;
  margin-bottom: 20px;
  margin-right: 20px;
}

.administartor-box-close {
  position: absolute;
  right: 0;
  top: 12px;
  cursor: pointer;
  /* display: none; */
}

.add-team-btn a {
  background: #121d55;
  color: #fff !important;
  padding: 13px 15px 13px 20px;
  display: inline-block;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 100;
  border-radius: 10px;
  text-decoration: none;
  width: 260px;
}

.add-team-btn a img {
  float: right;
}

.add-team-btn a:hover {
  background: #182b8c;
}

.clinic-detail-inner input.form-control {
  background: #f8f8f8;
  border-radius: 10px;
  border-width: 0;
  margin-bottom: 20px;
  font-size: 14px;
  /* display: none; */
  margin-top: -12px;
}

.edit-mode-on input.form-control {
  display: block;
}

.edit-mode-on input.form-control {
  display: block;
}

.edit-mode-on p {
  display: none;
}

.clinic-box-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: none;
  z-index: 99;
}

.clinic-detail-box.box-inactive .clinic-box-overlay {
  display: block;
}

.edit-mode-on .save-changes-btn {
  display: inline-block;
}

.edit-mode-on .edit-clinic-detail,
.edit-mode-on .edit-payment-detail {
  display: none;
}

/* The container */
.custom-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio  */
.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio  */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid #182b8c;
}

/* On mouse-over, add a grey background color */
.custom-radio:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio  is checked, add a blue background */
.custom-radio input:checked ~ .checkmark {
  background-color: transparent;
  border: 2px solid #182b8c;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom-radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custom-radio .checkmark:after {
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #182b8c;
}

.administartor-radio {
  position: absolute;
  left: 15px;
  top: 20px;
  /* display: none; */
}

.clinicians-list.edit-clinic-on .administartor-box-close {
  display: block;
}

.clinic-administartor-box.edit-clinic-box .administartor-radio {
  display: block;
}

.careplan-user img {
  float: left;
  margin-right: 20px;
}

.careplan-user-name h3 {
  font-size: 24px;
  font-weight: 400;
}

.careplan-user-name p {
  font-size: 14px;
  font-weight: lighter;
  margin: 0;
}

.careplan-head-menu ul {
  margin: 0;
  list-style: none;
  padding: 0;
  display: inline-block;
}

.careplan-head-menu ul li {
  display: inline-block;
  margin: 10px 20px;
}

.careplan-head-menu ul li a {
  display: inline-block;
  padding: 3px 0 5px;
  color: #121d55;
  text-decoration: none;
}

a.careplan-menu-active {
  border-bottom: 2px solid;
  font-weight: bold;
}

.careplan-head-menu ul li a img {
  width: 20px;
}

.careplan-head-menu ul li:last-child a {
  border-width: 0px !important;
}

.blue-btn {
  background: #121d55 !important;
  border: 2px solid #121d55 !important;
  color: #fff !important;
  padding: 8px 15px !important;
  display: inline-block;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 100;
  border-radius: 10px !important;
  text-decoration: none;
}

button.btn.blue-transparent {
  border: 2px solid #121d55;
  border-radius: 10px;
  padding: 8px 15px;
}

button.btn.blue-transparent:hover {
  border: 2px solid #121d55;
  border-radius: 10px;
  padding: 8px 15px;
  background: #121d55;
  color: #fff;
}

.blue-btn:hover {
  background: #182b8c;
  color: #fff;
}

.careplan-title-btns {
  float: right;
}

.careplan-title-btns .blue-transparent {
  margin-right: 20px;
}

.careplan-title {
  box-shadow: 0px 1px 10px #00000029;
  padding: 25px;
  border-radius: 10px;
  margin-top: 30px;
  background: #fff;
}

.careplan-title h2 {
  font-size: 40px;
  margin: 0;
  font-weight: 400;
}

.careplan-title-btns .btn.blue-btn {
  font-size: 16px;
}

.careplan-title-btns .btn.blue-btn img {
  float: right;
  margin-left: 20px;
  margin-top: 3px;
}

.care-plan-box {
  box-shadow: 0px 1px 10px #00000029;
  padding: 25px;
  border-radius: 10px;
  margin-top: 30px;
  background: #fff;
}

.care-plantable-heading p {
  font-size: 14px;
  color: #fa7a00;
  margin: 0;
  font-weight: lighter;
}
.line-section {
  display: none !important;
}

span.forgot-pass.verfication-email {
  float: left !important;
}
.care-plantable-heading p span {
  font-size: 10px;
  background: #fa7a00;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  color: #fff;
  text-align: center;
  line-height: 1.1;
  margin-left: 5px;
}

button.log-btn.step-btn2.add-care-task {
  margin-top: 10px !important;
}

p.dnthaveacc.verification-link.header-part-pd {
  margin-bottom: 0px !important;
  margin-top: 15px !important;
}

.care-plantable-head {
  padding-bottom: 10px;
}

.care-plantable-heading h3 {
  font-size: 26px;
}

.care-plantable-right {
  float: right;
}

.team-mem-images img {
  margin-left: -10px;
  float: right;
}

.patient-name-table span {
  font-weight: normal;
}

.patient-name-table p {
  font-weight: normal;
  font-size: 14px;
  margin: 0;
}

td.clinic-pateint-name img {
  float: left;
  margin-right: 15px;
}

span.incomplete-status {
  color: #ef0000;
}

.clinic-date {
  width: 105px;
  background: #fa7a00;
  color: #fff;
  font-weight: lighter;
  border-radius: 10px;
  padding: 8px;
}

span.inprocess-status {
  color: #fa7a00;
}

.log-btn-grey {
  height: 44px;
  text-align: center;
  border-radius: 10px;
  width: 100%;
  background: #dfdfdf;
  color: #121d55;
  border: 0px;
}

.care-box-btn,
.care-template-box-btn {
  display: inline-block;
  width: 100%;
  border-top: 1px solid #dee2e6;
  padding-top: 20px;
}

.care-box-btn .log-btn.step-btn {
  width: 80%;
}

.care-template-box-btn .log-btn.step-btn {
  width: 100%;
  text-decoration: none !important;
}

.care-box-btn .log-btn-grey.step-btn {
  width: 18%;
  float: right;
  margin-left: 2%;
}

.care-box-btn .step-btn.done-inactive-btn {
  width: 18%;
  float: right;
  margin-left: 2%;
  /* display: none; */
}

.care-plan-box.show-inactive-tasks .show-inactive-btn {
  display: none;
}

.care-plan-box.show-inactive-tasks .step-btn.done-inactive-btn {
  display: block;
}

.care-plan-box.show-inactive-tasks .log-btn.step-btn.add-patient-btn {
  background: #dfdfdf;
  pointer-events: none;
}

span.complete-status {
  color: #4fc931;
}

.finished-btn-careplan {
  float: right;
  /* display: none; */
}

.finished-btn-careplan .btn.blue-btn {
  padding: 10px 30px 10px 30px;
}

td.completed-task-name {
  font-size: 25px;
}

.completed-task-list .patient-table {
  background: #dfdfdf;
  border-radius: 10px;
  padding: 10px;
  margin-top: 20px;
}

.completed-task-list .patient-table table.table {
  margin: 0;
}

button.btn.white-btn {
  background: #fff;
  padding: 10px 30px;
  border-radius: 10px;
  color: #121d55;
}

button.btn.white-btn:hover {
  background: #121d55;
  color: #fff;
}

.completed-task-list .patient-table td:last-child {
  width: 150px;
}

.care-plantable-right .team-mem-images {
  float: right;
}

a.care-plantable-edit {
  display: inline-block;
  margin-left: 40px;
  padding: 8px 0;
}

.coordinate-contributors h4 {
  font-size: 14px;
}

.coordinate-contributors {
  margin-top: 20px;
}

.coordinate-contributors .clinicians-list {
  width: 100%;
}

.coordinate-contributors .administartor-box-close {
  display: block;
}

.coordinate-contributors {
  margin-top: 20px;
  /* display: none; */
}

button.btn.sky-blue-btn {
  background: #12cae6;
  color: #fff;
  padding: 9px 20px;
  border-radius: 10px;
}

.edit-coordinate-btns {
  float: right;
  /* display: none; */
}

.edit-coordinate-btns .btn.sky-blue-btn {
  margin-left: 20px;
}

.complete-condition-pop {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.95);
  width: 100%;
  height: 100%;
  /* display: none; */
  z-index: 9999999;
  overflow: auto;
  /* background: url(/assets/img/blurbg.png) center center no-repeat; */
  background-size: cover;
}

.complete-condition-outer {
  display: table;
  width: 100%;
  height: 100%;
}

.complete-condition-inner {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.complete-condition-box {
  width: 620px;
  background: #fff;
  margin: 50px auto;
  box-shadow: 0px 3px 6px #00000029;
  padding: 20px;
  border-radius: 20px;
}

.complete-pop-head h3 {
  font-size: 20px;
}

.complete-pop-head {
  position: relative;
}

.complete-pop-close {
  position: absolute;
  right: 0;
  cursor: pointer;
  top: -4px;
}

.complete-pop-con p {
  font-size: 18px;
  font-weight: lighter;
}

.complete-pop-con {
  margin-top: 30px;
}

.complete-pop-con h2 {
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 50px;
}

.complete-pop-con p.complete-pop-condition {
  font-size: 15px;
  color: #fa7a00;
}

.btn.red-transparent {
  border: 2px solid #ef0000;
  border-radius: 10px;
  padding: 8px 15px;
  color: #ef0000;
}

.btn.red-transparent:hover {
  border: 2px solid #ef0000;
  border-radius: 10px;
  padding: 8px 15px;
  background: #ef0000;
  color: #fff;
}

.complete-pop-btn {
  width: 49%;
}

.complete-pop-btn :last-child {
  margin-left: 2%;
}

.complete-pop-btn {
  margin-top: 25px;
}

.care-plan-radios .administartor-box-name p {
  font-size: 21px;
  margin: 0;
}

.care-plan-radios .administartor-radio {
  display: block;
  top: 15px;
}

.care-plan-radios .clinic-administartor-box {
  padding: 15px;
  background: #f8f8f8;
  box-shadow: unset;
  width: 32%;
  margin-right: 2%;
}

.care-plan-radios .clinic-administartor-box:nth-child(3n) {
  margin-right: 0px;
}

.add-care-plan label {
  font-size: 14px;
  color: #fa7a00;
  margin: 0;
  font-weight: lighter;
}

.care-box-btn.add-care-btns {
  border-top: 0px solid #dee2e6;
}

.care-box-btn.add-care-btns .log-btn-grey.step-btn.add-patient-btn {
  width: 18%;
  float: left;
  margin-left: 0;
  margin-right: 2%;
}

.care-plan-radios {
  margin-bottom: 20px;
}

.care-plan-input {
  margin-top: 15px;
}

.care-plan-input input {
  margin-top: 15px;
  background: #f8f8f8 !important;
  border-width: 0;
  font-size: 21px;
}

.care-plan-box.show-addplan-box .care-plan-radios .administartor-box-name p {
  text-align: center;
}

.administartor-box-name a {
  color: #121d55;
  text-decoration: none;
}

.common-popup {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.95);
  width: 100%;
  height: 100%;
  /* display: none; */
  overflow: auto;
  z-index: 9999999;
}

.common-popup-outer {
  display: table;
  width: 100%;
  height: 100%;
}

.common-popup-inner {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.common-popup-box {
  width: 480px;
  margin: 50px auto;
  box-shadow: 0px 1px 12px 0px #00000029;
  background: #f8f8f8;
  padding: 0;
  border-radius: 20px;
}

.common-popup-box2 {
  width: 660px;
  margin: 50px auto;
  box-shadow: 0px 1px 12px 0px #00000029;
  background: #f8f8f8;
  padding: 0;
  border-radius: 20px;
}

.common-popup-head h2 {
  font-size: 20px;
}

.common-popup-head {
  position: relative;
  padding: 20px;
  background: #fff;
}

.common-popup-head2 {
  position: relative;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  background: #fff;
}

.common-pop-close {
  position: absolute;
  right: 15px;
  cursor: pointer;
  top: 20px;
}

.select-appointment h3 {
  font-size: 20px;
  font-weight: lighter;
  margin-bottom: 30px;
}

.select-appointment {
  display: inline-block;
  width: 100%;
  padding: 40px 0 0;
}

.select-appointment .log-btn.step-btn {
  margin-top: 10px;
}

.common-popup-innersec {
  background: #f8f8f8;
  padding: 20px;
}

.care-tags {
  margin-top: 20px;
}

.care-tags span {
  background: #dfdfdf;
  font-size: 14px;
  padding: 5px 10px;
  display: inline-block;
  border-radius: 10px;
  margin-right: 15px;
  margin-bottom: 10px;
}

.book-appointment-form label {
  font-size: 14px;
  color: #fa7a00;
}

.book-appointment-form .form-control {
  border-width: 0;
  font-size: 14px;
}

.form-radios label.custom-radio {
  color: #121d55;
  margin-top: 10px;
}

.care-plan-box.confirm-add-plan
  .care-box-btn
  .log-btn.step-btn.add-patient-btn {
  width: 49%;
}

button.btn.orange-transparent {
  color: #fa7a00;
  border: 2px solid #fa7a00;
}

button.btn.orange-transparent:hover {
  background: #fa7a00;
  border: 2px solid #fa7a00;
  color: #fff;
}

.care-plan-box.confirm-add-plan .btn.orange-transparent.step-btn {
  width: 49%;
  border-radius: 10px;
  padding: 7px;
  margin-left: 2%;
}

.care-plan-box.confirm-add-plan {
  display: none;
}

.custom-file-upload {
  font-size: 14px;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  position: relative;
}

.custom-file-upload img {
  position: absolute;
  right: 10px;
  top: 11px;
}

.custom-file-upload input {
  position: absolute;
  right: 0;
  width: 100px;
  overflow: hidden;
  opacity: 0;
  z-index: 99;
}

.book-appointment-form .form-group {
  position: relative;
}

img.input-google {
  position: absolute;
  top: 39px;
  right: 8px;
}

.document-upload .css-cw19kr-DropDown {
  z-index: 999999;
}

.select-country span {
  font-size: 14px;
  padding: 10px 0;
  display: inline-block;
}

.select-country .custom-select-input {
  float: right;
  width: 100px;
}

.inactive-task-head {
  font-size: 14px;
  font-weight: lighter;
  border-top: 1px solid #121d55;
  padding: 10px 0;
}

.patient-table .table tbody tr td:first-child {
  padding-left: 0;
}

.patient-table.inactive-tasks-table .clinic-date {
  background: #dfdfdf;
}

.patient-table.inactive-tasks-table td {
  color: #dfdfdf;
}

.care-plan-box.show-inactive-tasks .patient-table.inactive-tasks-table {
  display: block;
}

.document-table-filter a {
  background: #dfdfdf;
  color: #121d55;
  font-size: 15px;
  display: inline-block;
  padding: 11px 10px;
  border-radius: 10px;
  margin-right: 20px;
  width: 170px;
  text-align: center;
  height: 45px;
  text-decoration: none;
}

.document-table-filter {
  margin-bottom: 20px;
}

.document-table-filter a.document-filter-active {
  background: #121d55;
  color: #fff;
}

.patient-table .table thead th:first-child {
  padding-left: 0;
}

.patient-table .care-tags {
  margin: 0;
}

.table-action {
  width: 120px;
  text-align: center;
}

td.table-action button {
  border-width: 0;
  background: transparent;
  margin: 0 5px;
}

.downloading-con p {
  margin: 0;
}

.downloading-con {
  text-align: left;
  display: none;
}

.prfile-setting-menu a {
  display: block;
  text-align: center;
  margin: 0px 0 25px;
}

.setting-active {
  display: none;
}

.profile-seting-area {
  position: fixed;
  width: 350px;
  background: #fff;
  left: 100px;
  height: 100%;
  top: 0;
  padding: 20px;
  z-index: 999;
  box-shadow: 5px 0px 15px #0000001f;
  /* display: none; */
  overflow: auto;
}

.profile-close {
  position: absolute;
  right: 25px;
  cursor: pointer;
}

.profile-info {
  margin-top: 100px;
}

.profile-img-area {
  width: 100px;
  float: left;
}

.profile-img {
  display: inline-block;
  width: 100%;
}

.profile-img-area img {
  width: 100%;
}

.profile-img-area {
  width: 100px;
  float: left;
  margin-right: 20px;
  position: relative;
}

.profile-name-edit {
  display: none;
}

.profile-img a {
  color: #fa7a00;
  font-size: 12px;
  text-decoration: underline;
}

.profile-name-area {
  margin: 30px 0;
}

.profile-name-area h2 {
  font-size: 26px;
  font-weight: lighter;
  margin: 0;
}

.profile-name-area p {
  color: #12cae6;
}

.profile-detail-area label {
  color: #fa7a00;
  font-size: 12px;
  font-weight: lighter;
}

.profile-detail-area p {
  font-size: 14px;
}

.profile-seting-area input.form-control {
  background: #f8f8f8;
  border-width: 0;
  font-size: 14px;
  display: none;
}

.profile-img-area button.change-img {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #fa7a00;
  color: #fff;
  width: 100%;
  text-align: center;
  padding: 5px;
  border-radius: 0 0 15px 15px;
}

.profile-seting-area.profile-seting-edit a.change-img {
  display: block;
}

.profile-seting-area.profile-seting-edit .profile-name-edit {
  display: block;
  margin-top: 40px;
}

.profile-seting-area.profile-seting-edit .profile-name-area {
  display: none;
}

.profile-seting-area.profile-seting-edit input.form-control {
  display: block;
}

.profile-seting-area.profile-seting-edit .profile-detail-area p {
  display: none;
}

.save-profile-btn {
  display: none;
}

.profile-seting-area.profile-seting-edit .save-profile-btn {
  display: block;
}

.profile-seting-area.profile-seting-edit a.edit-profile-btn {
  display: none;
}

a.profile-set.profile-set-active img.setting-active {
  display: inline-block;
}

a.profile-set.profile-set-active img {
  display: none;
}

.patient-table.doument-table-deleted {
  display: none;
}

.common-popup.logout-pop .complete-condition-box {
  width: 400px;
}

.common-popup.logout-pop .common-pop-close {
  top: 0;
}

.common-popup.logout-pop .complete-pop-con p {
  margin: 50px 0;
  font-size: 15px;
}

.update-patient-btns {
  display: inline-block;
  width: 100%;
  margin-top: 30px;
}

.common-popup.pateint-detail-pop .common-popup-box {
  width: 600px;
}

.update-patient-btns .btn.red-transparent {
  width: 20%;
  float: left;
}

.update-patient-btns .step-btn {
  width: 77%;
  float: right;
  margin-top: 0;
}

.update-patient-btns .step-btn2 {
  width: 100%;
  margin-top: 0;
}

.progress-dashboard {
  margin-top: 40px;
}

h2.progress-title {
  font-size: 30px;
  font-weight: 500;
  border-top: 1px solid #ddd;
  padding-top: 15px;
  margin-bottom: 0;
}

.progress-dashboard h3 {
  font-size: 21px;
  font-weight: lighter;
}

.patient-status-left ul {
  padding: 0 0 0 18px;
  color: #919191;
  font-size: 15px;
  font-weight: lighter;
  margin-top: 15px;
}

.patient-status-left {
  width: 70%;
  padding-right: 30px;
  border-right: 1px solid #eaeaea;
  float: left;
}

.progress-conditions {
  display: inline-block;
  width: 100%;
  margin-top: 20px;
}

.conditions-tags {
  width: 60%;
  float: left;
}

.tasks-tags {
  width: 185px;
  float: right;
}

.task-tags {
  margin-top: 20px;
}

.task-tags span {
  background: #dfdfdf;
  padding: 5px 10px;
  width: 100%;
  display: inline-block;
  margin-bottom: 10px;
  border-radius: 10px;
  font-size: 14px;
}

.task-tags span i {
  float: right;
  margin-top: 4px;
}

.task-tags span.tag-active {
  background: #121d55;
  color: #fff;
}

.patient-status-box {
  display: inline-block;
  width: 100%;
}

.patient-status-right {
  display: inline-block;
  width: 29%;
  padding: 20px;
}

.small-box {
  box-shadow: 0px 1px 10px #00000029;
  padding: 15px;
  border-radius: 10px;
  margin-top: 30px;
  min-height: 157px;
  background: #fff;
}

.progress-dashboard .small-box-head h3 {
  font-size: 16px;
}

.progress-dashboard .small-box-head h3 i {
  float: right;
}

.plain-level-graph {
  height: 100px;
}

.weight-orange {
  font-size: 45px;
  color: #fa7a00;
}

.weight-green span {
  display: inline-block;
  border-top: 17px solid #4fc931;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  margin-right: 8px;
}

.weight-green {
  color: #4fc931;
  font-size: 24px;
}

.steps-goal {
  text-align: center;
}

.steps-goal h3 {
  font-size: 31px;
  margin: 0;
  font-weight: 500;
}

.steps-goal span {
  font-size: 25px;
}

.steps-graph {
  display: block;
  width: 150px;
  height: 150px;
  margin: 37px auto;
}

.steps-graph-new-height {
  height: 133px !important;
}

.weight-text {
  color: #fa7a00;
}

.progress-media {
  padding: 10px 0;
}

.progress-right .small-box {
  margin-top: 0;
}

.progress-right-title h2 {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 0;
}

.progress-right-title {
  position: relative;
}

.right-seeall-action {
  position: absolute;
  right: 0;
  top: 7px;
}

.right-seeall-action a {
  color: #121d55;
  text-decoration: none;
}

.progress-alert-box {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  padding: 15px;
  display: inline-block;
  width: 100%;
  margin-top: 15px;
  cursor: pointer;
}

.progress-alert-date {
  float: left;
  font-size: 40px;
  color: #fa7a00;
  line-height: 1;
  width: 50px;
  font-weight: 500;
}

.progress-alert-date span {
  display: block;
  font-size: 21px;
  font-weight: 400;
}

.progress-alert-time p {
  margin: 0;
}

.progress-alert-time {
  font-size: 18px;
  padding: 5px 20px 0;
  float: left;
  width: calc(100% - 50px);
}

.progress-alerts {
  margin-bottom: 30px;
}

.right-activity-action {
  position: absolute;
  right: 0;
  top: 7px;
}

.right-activity-action a {
  font-size: 18px;
  color: #121d55;
  display: inline-block;
  margin-left: 20px;
}

.right-activity-action a.act-mactive {
  font-weight: 500;
  text-decoration: underline;
}

.activity-img {
  width: 30px;
  float: left;
}

.activity-img img {
  max-width: 100%;
}

.activity-detail {
  float: left;
  width: calc(100% - 30px);
  padding: 0 20px;
  position: relative;
}

.activity-all-detail {
  float: left;
  width: calc(100% - 60px);
  padding: 0 20px;
  position: relative;
}

.activity-detail-box {
  display: inline-block;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid #e8e8e8;
}

.activity-list {
  margin-top: 10px;
}

.patient-list {
  margin-top: 10px;
  display: none;
}

.activity-detail h4,.activity-all-detail h4 {
  font-size: 18px;
  color: #121d55;
  margin: 0;
}

span.activity-time {
  color: #fa7a00;
  font-size: 14px;
  position: absolute;
  right: 0;
  top: 0;
}

.activity-detail p,.activity-all-detail p {
  margin: 0;
}

.progress-small-boxes .small-box {
  cursor: pointer;
}

.weight-form-field label {
  font-size: 12px;
}

.weight-form-field .weight-orange {
  float: left;
  font-size: 26px;
}

.weight-form-field .progress-weight {
  display: inline-block;
  width: 100%;
}

.weight-form-field .weight-green span {
  display: inline-block;
  border-top: 10px solid #4fc931;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  margin-right: 5px;
}

.weight-form-field .weight-green {
  font-size: 12px;
  float: left;
  margin-top: 15px;
  margin-left: 10px;
}

.weight-form-field .weight-orange {
  float: left;
  font-size: 26px;
}

.body-weight-form {
  display: inline-block;
  width: 100%;
}

.weight-form-field {
  float: left;
  width: 30%;
}

.common-popup.body-weight-pop .common-popup-box {
  width: 600px;
}

.weight-form-field.goal-weight .weight-orange {
  color: #121d55;
}

.measurment-weight-form {
  display: inline-block;
  width: 100%;
}

.measurment-weight-form input.form-control {
  width: 67%;
  float: left;
  border-width: 0;
  height: 40px;
}

.weight-form-field.measurment-weight {
  width: 40%;
}

.measurment-weight-form .log-btn.step-btn {
  width: 30%;
  float: right;
  margin: 0;
  height: 40px;
}

.body-weight-graph {
  margin: 10px 0;
}

.body-weight-table {
  font-size: 14px;
}

.body-weight-table .table thead th {
  border-bottom: 0px solid #dee2e6 !important;
  font-weight: 500;
}

.body-weight-table .table td,
.body-weight-table .table th {
  padding: 6px 10px;
  vertical-align: top;
  border-top: 0px solid #dee2e6;
}

.body-weight-table .table {
  color: #121d55;
}

.step-count-graph {
  text-align: center;
  background: #fff;
  padding: 20px;
  margin-bottom: 15px;
}

.step-count-txt h3 {
  font-size: 31px;
  font-weight: lighter;
  margin: 0;
}

.step-count-txt span {
  font-size: 12px;
}

.medication-progress {
  background: #fff;
  padding: 15px;
  margin-bottom: 20px;
  display: inline-block;
  width: 100%;
}

.medication-progress-title p {
  font-size: 14px;
  margin: 0;
  color: #121d55;
}

.medication-progress-title span {
  font-size: 14px;
  margin: 0;
  color: #12cae6;
}

.medication-progress-title {
  position: relative;
}

.medication-progress-count {
  font-size: 19px;
  color: #fa7a00;
  position: absolute;
  right: 0;
  top: 0;
}

.medication-progress .progress {
  height: 15px;
  background: #dfdfdf;
}

.progress.progress-orange .progress-bar {
  background-color: #fa7a00;
}

.progress.progress-green .progress-bar {
  background-color: #4fc931;
}

.common-popup.medication-pop .common-popup-box {
  width: 600px;
}

.medication-progress-count.progress-count-green {
  color: #4fc931;
}

.common-popup.media-pop .common-popup-box {
  width: 600px;
}

.media-diary-task h2 {
  font-size: 21px;
  font-weight: lighter;
  color: #121d55;
}

.media-list-box {
  margin: 20px 0;
}

.media-list-title h4 {
  font-size: 14px;
  font-weight: lighter;
  color: #121d55;
}

.media-list-title {
  position: relative;
}

.media-list-title a {
  font-size: 14px;
  position: absolute;
  right: 0;
  top: 0;
  color: #fa7a00;
  text-decoration: underline;
}

.media-task-list ul {
  padding: 0;
  list-style: none;
}

.media-task-list ul li {
  width: 32%;
  display: inline-block;
}

img {
  max-width: 100%;
}

.media-task-list ul {
  padding: 0;
  list-style: none;
  width: 116%;
  margin: 0;
}

.media-task-list ul li {
  width: 150px;
  display: inline-block;
  margin-right: 10px;
}

.media-task-list {
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 40px;
}

.media-task-list ul li a {
  text-decoration: none;
  font-size: 12px;
  color: #121d55;
}

.media-name-date span {
  display: block;
}

.media-name-date {
  margin-top: 10px;
}

.common-popup.media-full-pop .common-popup-box {
  width: 80%;
  max-width: 1200px;
  position: relative;
}

.media-img-close {
  position: absolute;
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  color: #fff;
  text-align: center;
  font-size: 25px;
  padding-top: 6px;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.common-popup.media-full-pop .common-popup-box .media-name-date {
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: 20px;
  left: 20px;
  padding: 20px 20px 25px;
  width: 270px;
  border-radius: 10px;
  font-size: 30px;
  color: #fff;
}

.common-popup.media-full-pop .common-popup-box .media-name-date span {
  font-size: 20px;
}

.progress-alert-time span {
  font-weight: 500;
  color: #121d55;
}

.plain-level-graph img {
  max-height: 100%;
}

.patient-status-graph {
  text-align: center;
  /* padding-top: 60px;
  padding-left: 20px;
  padding-right: 20px; */
}

.password-error-field {
  color: #ef0000;
  font-size: 12px;
  text-align: right;
  width: 100%;
  display: none;
}

.care-plan-box.add-careplan-box .log-btn.step-btn.add-patient-btn {
  width: 49%;
}

.care-plan-box.add-careplan-box .log-btn-grey.step-btn.show-inactive-btn {
  width: 49%;
  margin-left: 2%;
  background: transparent;
  border: 2px solid #dfdfdf;
  color: #dfdfdf;
}

button.btn.orange-btn {
  background: #fa7a00;
  color: #fff;
  width: 100%;
  text-align: left;
  padding: 10px 20px;
  border-radius: 10px;
}

button.btn.orange-btn:hover {
  background: #d86c05;
}

.clinic-date.inactive-clinic-date {
  background: #dfdfdf;
  text-align: center;
  min-height: 55px;
  padding-top: 15px;
}

.upload-document-pop .step-progress {
  margin-top: 35px;
}

.upload-document-pop .step-outter {
  width: 350px;
}

.step-form.upload-doc-form label {
  font-size: 12px;
  color: #fa7a00;
  margin: 0;
}

.field-after-doc input.form-control {
  border-width: 0;
}

.step-wrapper.common-pop.dashboard-success-pop .step-outter {
  width: 310px;
}

.step-wrapper.common-pop.dashboard-success-pop h4 {
  color: #12cae6;
}

.step-wrapper.common-pop.dashboard-success-pop .step-top {
  padding: 20px;
}

.dashboard-success-pop .success-patient-inner p:last-child {
  font-weight: 500;
}

.step-wrapper.common-pop.subscription-pop {
  left: 0;
  background: rgba(255, 255, 255, 0.95);
}

.step-wrapper.common-pop.subscription-pop .step-outter {
  width: 90%;
  max-width: 1160px;
  /* box-shadow: 1px 2px 10px rgb(0 0 0 / 0.16); */
  box-shadow: 0px 1px 12px 0px #00000029;
}

.subscription-pop-head h2 {
  font-size: 22px;
  margin-bottom: 5px;
}

.subscription-pop-head p {
  font-size: 14px;
}

.subscription-pop-head {
  padding: 20px 30px;
}

.subscription-pop-inner {
  position: relative;
  background: #fff;
  padding-bottom: 40px;
}

.subscription-pop-inner .pop-close-cross {
  position: absolute;
  right: 20px;
  top: 20px;
}

.subscription-plan-list {
  padding: 20px 60px;
  display: flex;
  align-items: center;
}

.subscription-plan-box {
  width: 31%;
  display: inline-block;
  margin: 0 12px;
  background: #f8f8f8;
  box-shadow: 5px 5px 10px #00000029;
  border-radius: 20px;
  padding: 30px 40px 20px;
  text-align: center;
}

.subscription-plan-name {
  font-size: 14px;
  color: #121d55;
  font-weight: 500;
}

.subscription-plan-price {
  font-size: 40px;
  font-weight: 500;
  margin: 30px 0;
}

.subscription-plan-description {
  font-size: 14px;
  line-height: 24px;
}

.subscription-plan-box {
  display: inline-block;
  float: none;
  margin-top: 30px;
}

.subscription-plan-price span {
  font-size: 20px;
}

.subscription-plan-box.small-plan-box {
  background: #121d55;
  color: #fff;
}

.subscription-plan-box.small-plan-box .subscription-plan-name {
  color: #fff;
}

.subscription-plan-box .log-btn.step-btn.orange-btn {
  background: #fa7a00;
}

.subscription-plan-box .log-btn.step-btn.orange-btn:hover {
  background: #e06e02;
}

.checkout-pop .patient-pop-box .step-progress {
  margin: 35px 0 0;
}

.step-wrapper.common-pop.checkout-pop {
  left: 0;
}

.checkout-summary h3 {
  font-weight: normal;
  font-size: 22px;
  margin-bottom: 20px;
}

.checkout-summary label {
  font-size: 12px;
  color: #fa7a00;
  margin-bottom: 5px;
  font-weight: normal;
}

.summary-detail-sec {
  display: inline-block;
  width: 33%;
  font-size: 14px;
  color: #121d55;
  font-weight: 500;
}

.checkout-price {
  text-align: right;
  border-top: 1px solid #eeee;
  padding-top: 10px;
  font-weight: 500;
}

.summary-detail {
  background: #fff;
  padding: 20px;
  margin: 20px 0;
  display: inline-block;
  width: 100%;
}

.summary-detail.payment-detail-form {
  background: transparent;
  margin-bottom: 0;
  padding: 0;
}

.payment-form input.form-control {
  font-size: 14px;
  border-width: 0;
}

.checkout-pop .step-2 {
  display: none;
}

.checkout-txt {
  margin-top: 20px;
}

.checkout-form {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}

p.payment-form-error {
  float: left;
  font-size: 12px;
  color: #ef0000;
  margin: 8px 0 0;
  display: none;
}

button.log-btn.step-btn.free-plan-btn.downgrade-btn {
  background: #dfdfdf;
}

button.log-btn.step-btn.free-plan-btn.disable-btn {
  color: #121d55 !important;
}

button.log-btn.step-btn.small-plan-btn.select-plan-btn.orange-transparent {
  background: transparent;
  border: 2px solid #fa7a00;
  color: #fa7a00;
}

button.log-btn.step-btn.small-plan-btn.select-plan-btn.orange-transparent:hover {
  background: #fa7a00;
  border: 2px solid #fa7a00;
  color: #fff;
}

.complete-condition-pop.downgrade-pop .complete-condition-box {
  width: 340px;
}

.downgrade-content p {
  font-size: 14px;
  color: #121d55;
}

.downgrade-content {
  padding: 40px 0 10px;
}

.downgrade-success-pop .step-outter {
  width: 310px;
}

.complete-condition-pop.delete-patient-pop .complete-condition-box {
  width: 330px;
}

.downgrade-content img {
  margin: 0 auto;
  display: block;
  margin-bottom: 30px;
}

.delete-patient-pop .complete-pop-btn {
  width: 48%;
}

.delete-patient-pop .complete-pop-btn :last-child {
  margin-left: 4%;
}

.delete-patient-pop .complete-pop-btn .btn.red-transparent {
  padding: 7px 15px;
}

.no-condition-sec {
  text-align: center;
  padding: 150px;
}

.no-condition-sec p {
  font-size: 18px;
  color: #121d55;
}

.no-condition-sec p a {
  font-size: 18px;
  font-weight: 500;
  color: #121d55;
  text-decoration: none;
  border-bottom: 1px solid;
}

.care-plan-box.add-careplan-box2 .log-btn.step-btn.add-patient-btn {
  width: 100%;
}

.common-popup-head .step-progress {
  float: none;
  display: inline-block;
  margin-top: 10px;
}

.reset-password-form .log-btn.step-btn {
  float: none;
}

.reset-password-form label {
  font-size: 12px;
  margin: 0;
  color: #fa7a00;
}

.reset-password-form .form-control {
  font-size: 14px;
  border-width: 0;
  color: #121d55;
}

.reset-email-sec p {
  margin-bottom: 30px;
}

.reset-code-sec {
  display: none;
}

.not-get-code {
  color: #fa7a00 !important;
  font-weight: 500;
  float: right;
  margin-top: 10px;
  font-size: 12px;
  cursor: pointer;
  position: relative;
  z-index: 99;
}

.enter-new-password {
  display: none;
}

.enter-new-password ul {
  padding-left: 0;
  list-style: none;
}

p.code-streangth {
  font-size: 10px;
  text-align: right;
  display: none;
}

.step-wrapper.common-pop.resetpass-success-pop {
  background: rgba(255, 255, 255, 0.95);
  left: 0;
}

.step-wrapper.common-pop.resetpass-success-pop .step-outter {
  width: 330px;
}

.step-wrapper.common-pop.resend-code-pop {
  left: 0;
  background: rgba(255, 255, 255, 0.95);
}

.resend-code-inner .log-btn.step-btn {
  float: none;
}

.resend-code-inner {
  text-align: center;
}

.resend-code-inner img {
  margin: 40px auto;
  display: inline-block;
}

button.log-btn.step-btn.blue-transparent {
  background: transparent;
  color: #12cae6;
  border: 2px solid #12cae6;
}

button.log-btn.step-btn.blue-transparent:hover {
  background: #12cae6;
  color: #fff;
  border: 2px solid #12cae6;
}

.or-sec {
  border-bottom: 2px solid #c7c7c7;
  position: relative;
  margin: 40px 0 30px;
}

.or-sec span {
  position: absolute;
  bottom: -12px;
  background: #fff;
  width: 60px;
  left: 44%;
}

.step-wrapper.common-pop.resend-code-pop .step-p-text h4 {
  color: #12cae6;
  font-size: 22px;
  font-weight: 600;
}

.step-wrapper.common-pop.resend-code-pop .step-outter {
  width: 330px;
}

.reset-form .form-group {
  position: relative;
}

a.show-password {
  position: absolute;
  right: 10px;
  top: 29px;
}

.complete-condition-pop.remove-member-pop .complete-condition-box {
  width: 400px;
}

.complete-pop-con .remove-member-content p {
  font-size: 14px;
}

.remove-member-content .clinic-administartor-box {
  width: 270px;
  margin: 30px auto 50px;
  display: block;
}

p.coordinate-pop-link,
p.contributor-pop-link,
p.viewaccess-pop-link {
  cursor: pointer;
}

.complete-condition-pop.coordinate-about-txt .log-btn.step-btn,
.complete-condition-pop.contributor-about-txt .log-btn.step-btn,
.complete-condition-pop.view-access-pop .log-btn.step-btn {
  float: none;
}

.complete-condition-pop.coordinate-about-txt .complete-condition-box,
.complete-condition-pop.contributor-about-txt .complete-condition-box,
.complete-condition-pop.view-access-pop .complete-condition-box,
.complete-condition-pop.care-plancode-pop .complete-condition-box {
  width: 330px;
}

.coordinate-about-content p,
.contributor-about-content p {
  font-size: 14px;
  color: #121d55;
}

.coordinate-about-content {
  margin-top: 50px;
  margin-bottom: 20px;
}

.complete-condition-pop.coordinate-about-txt .complete-pop-head h3,
.complete-condition-pop.contributor-about-txt .complete-pop-head h3,
.complete-condition-pop.view-access-pop .complete-pop-head h3,
.complete-condition-pop.care-plancode-pop .complete-pop-head h3 {
  color: #12cae6;
}

.complete-condition-pop.care-plancode-pop .coordinate-about-content {
  margin-top: 0;
}

.coordinate-about-content img {
  display: block;
  margin: 5px auto 30px;
}

.complete-condition-pop.care-plancode-pop .pop-close-cross {
  position: absolute;
  right: 0;
  top: 0;
}

.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 9999999;
  width: 100%;
  padding: 8px 20px;
  box-shadow: 5px 0px 15px #0000001f;
  display: none;
}

.mobile-menu .dashboard-logo {
  float: left;
  margin: 0;
  padding: 4px 0;
}

.mobile-menu .leftbar-bottom {
  position: static;
  float: right;
  width: auto;
}

.toggle-menu {
  float: right;
  display: none;
}

.toggle-menu a {
  font-size: 26px;
  padding: 8px 15px 0;
  color: #121d55;
  line-height: 1;
  display: inline-block;
}

.mobile-menu .prfile-setting-menu {
  float: left;
  position: absolute;
  top: 65px;
  background: #f5f5f5;
  right: 10px;
  width: 200px;
  border-radius: 20px;
  /* display: none; */
}

.mobile-menu .account-menu {
  float: left;
}

.mobile-menu .leftbar-bottom ul {
  float: left;
  margin: 0 10px 0 0;
  display: flex;
  list-style: none;
  border-width: 0;
  padding-top: 6px;
}

.mobile-menu .leftbar-bottom ul li {
  padding: 0 10px;
}

.mobile-menu .leftbar-bottom ul a img {
  display: block;
  margin: 0 auto;
  float: none;
  margin-right: 5px;
  width: 22px;
  padding-top: 5px;
}

.mobile-menu .leftbar-bottom ul a span {
  display: inline-block;
  margin: -8px 5px 0 0;
  width: 35px;
  height: 35px;
}

.mobile-menu .prfile-setting-menu a {
  display: block;
  text-align: left;
  margin: 0;
  padding: 15px;
  border-bottom: 1px solid #eee;
  color: #121d55;
  font-size: 15px;
}

.mobile-menu .prfile-setting-menu a img {
  float: left;
  margin-right: 10px;
  max-height: 20px;
  margin-top: 1px;
}

.mobile-menu .leftbar-bottom ul a {
  padding: 6px 0 0;
}

.mobile-menu .account-menu img {
  width: 40px;
}

span.mobile-menu-arrow {
  border-bottom: 10px solid #f5f5f5;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  display: inline-block;
  position: absolute;
  right: 42px;
  top: -6px;
}

td.clinic-pateint-name {
  width: 270px;
}

.step-wrapper.common-pop.existing-user-pop {
  display: block;
}

.step-wrapper.common-pop.existing-user-pop .step-outter {
  width: 600px;
  max-width: 90%;
}

.existing-user-detail {
  padding-top: 15px;
}

.existing-user-detail p {
  font-size: 14px;
  color: #121d55;
}

.existing-user-name {
  display: inline-block;
  background: #fff;
  padding: 20px;
  width: 100%;
}

.existing-user-img {
  float: left;
  width: 57px;
  margin-right: 20px;
}

.existing-user-txt h3 {
  font-size: 22px;
  color: #121d55;
  margin-bottom: 4px;
}

.existing-user-txt {
  float: left;
  width: 300px;
}

.existing-user-detail .existing-user-txt p {
  margin-bottom: 3px;
}

.existing-user-tags {
  float: right;
  width: 140px;
  text-align: right;
}

.existing-user-tags .care-tags {
  margin-top: 0px;
}

.common-popup.logout-pop .complete-pop-btn {
  width: 100%;
}

.common-popup.logout-pop .complete-pop-btn .btn {
  width: 49%;
}

.btn.blue-transparent.complete-condition-btn {
  position: relative;
}

span.complete-condition-count {
  width: 20px;
  height: 20px;
  background: #fa7a00;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  border-radius: 50%;
  position: absolute;
  top: -11px;
  right: -7px;
}

.complete-condition-pop.remove-member-pop .complete-pop-btn {
  width: 100%;
}

.complete-condition-pop.remove-member-pop .complete-pop-btn button {
  width: 49%;
}

.custm-loader {
  position: fixed;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  height: 100%;
  text-align: center;
  z-index: 99999999;
  padding-top: 22%;
}

.custm-loader img {
  width: 100px;
}

.camera-capture {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.95);
  /* background: url(/assets/img/blurbg.png) center center no-repeat; */
  background-size: cover;
  left: 0;
  top: 0;
  z-index: 99999;
}

.camera-capture-inner {
  width: 500px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 3px 4px 16px 0px #00000029;
  margin: 10% auto 0;
  padding: 40px 30px 30px;
  position: relative;
}

.camera-capture-inner a {
  position: absolute;
  right: 10px;
  top: 10px;
}

.camera-capture-inner video {
  width: 100%;
}

.camera-capture-inner button {
  height: 44px;
  text-align: center;
  border-radius: 10px;
  width: 100%;
  background: #12cae6;
  color: white;
  border: 0px;
  margin-top: 10px;
}

.subscription-plan-box .log-btn.step-btn {
  width: auto;
  padding: 10px 40px;
  margin-left: auto;
  display: block;
  margin-right: auto;
  float: none;
}

/*--------------------------------------------------------------------   Responsive ------------------------------------------------------*/
@media screen and (max-width: 1500px) {
  .add-patent-pop {
    left: 0;
    overflow: auto;
  }

  .patent-box-inner {
    width: 80%;
  }
}

@media screen and (max-width: 1300px) {
  .tasks-tags {
    width: 40%;
    float: right;
  }

  .progress-alert-time p {
    font-size: 14px;
  }

  .progress-alert-time span {
    font-size: 15px;
  }

  .progress-alert-time {
    padding: 0 0px 0 20px;
  }

  .activity-detail p,.activity-all-detail p {
    font-size: 14px;
  }

  .activity-detail h4,.activity-all-detail h4 {
    font-size: 16px;
  }

  .right-activity-action a {
    font-size: 15px;
  }

  span.activity-time {
    font-size: 12px;
  }

  .subscription-table .table thead th:first-child {
    width: 150px;
  }

  .subscription-table .table thead th:nth-child(2) {
    width: 150px;
  }

  .clinicians-list {
    display: inline-block;
    width: 100%;
  }

  .document-table-filter a {
    margin-right: 10px;
    width: 150px;
  }

  .dashboard-head-right {
    width: 100%;
  }

  .dashboard-inner-head .dashboard-head-right {
    width: 80%;
  }

  .subscription-plan-box {
    padding: 30px 20px 20px;
  }

  .subscription-plan-list {
    padding: 20px 20px;
  }
}

@media screen and (max-width: 1200px) {
  .progress-right {
    margin-top: 30px;
  }
}

@media screen and (max-width: 1100px) {
  .add-patent-pop {
    left: 0;
    overflow: auto;
  }

  .dashboard-head-search input {
    width: 200px;
  }

  .patent-box-inner {
    width: 90%;
  }

  .step-wrapper.common-pop {
    left: 0;
    z-index: 9999999;
  }

  .step-wrapper.patient-success-pop {
    left: 0;
    z-index: 9999999;
  }

  td.pateint-name img {
    margin-right: 10px;
    width: 50px;
  }

  td.team-mem-images img {
    margin-left: -10px;
    width: 40px;
  }
}

@media screen and (max-width: 990px) {
  .leftbar-menu {
    display: none;
  }

  .subscription-plan-list {
    padding: 20px 10px;
  }

  .subscription-plan-box {
    padding: 30px 10px 20px;
  }

  .subscription-plan-description {
    font-size: 12px;
    line-height: 20px;
  }

  .step-wrapper.common-pop.subscription-pop .step-outter {
    width: 95%;
  }

  .dashboard-inner-head .dashboard-head-right {
    width: 100%;
  }

  .careplan-title.document-title .dashboard-head-search input {
    width: 200px;
  }

  .careplan-title.document-title .dashboard-head-right {
    float: right;
    margin: 0;
  }

  .care-box-btn.add-care-btns .log-btn-grey.step-btn.add-patient-btn {
    width: 28%;
  }

  .care-plan-radios .administartor-box-name p {
    font-size: 17px;
  }

  .dashboard {
    display: inline-block;
    width: 100%;
    background: #f8f8f8;
    padding: 0 10px;
  }

  .clinic-detail-left {
    width: 100%;
    margin-right: 0;
    float: left;
  }

  .clinic-detail-right {
    width: 100%;
    float: right;
  }

  .mobile-menu {
    display: block;
  }

  .dashboard-inner-sec {
    width: 100%;
    margin-left: 0px;
    max-width: 100%;
  }

  div.dashboard {
    padding-top: 55px;
  }

  .dashboard-inner-head h2 {
    width: 100%;
  }

  .dashboard-head-right {
    display: inline-block;
    width: auto;
    margin-bottom: 10px;
  }

  .dashboard-head-search input {
    width: 280px;
  }

  .patient-table table.table {
    width: 1000px;
  }

  .profile-seting-area {
    width: 350px;
    left: 0;
    z-index: 9999999;
    overflow: auto;
  }

  .profile-info {
    margin-top: 40px;
  }

  .dashboard-inner-clinic {
    width: 100%;
    margin-left: 0%;
    max-width: 100%;
  }

  .careplan-title h2 {
    font-size: 34px;
  }

  .careplan-title-btns .btn.blue-transparent {
    padding: 6px 15px;
    font-size: 14px;
  }

  .careplan-title {
    padding: 15px;
  }

  .careplan-title-btns .btn.blue-btn {
    font-size: 14px;
    padding: 8px 15px 8px 20px;
  }

  .care-box-btn .log-btn.step-btn {
    width: 70%;
  }

  .care-box-btn .log-btn-grey.step-btn {
    width: 28%;
    margin-left: 2%;
  }

  .care-box-btn .step-btn.done-inactive-btn {
    width: 28%;
  }

  .document-table-filter a {
    margin-right: 10px;
    width: auto;
    margin-bottom: 10px;
    padding: 11px 20px;
  }
}

@media screen and (max-width: 767px) {
  .dashboard-head-right {
    width: 100%;
    position: relative;
  }

  .step-wrapper.common-pop.existing-user-pop .step-outter {
    width: 90%;
    max-width: 90%;
  }

  .existing-user-txt {
    float: left;
    width: 100%;
    margin: 20px 0;
  }

  .existing-user-tags {
    float: right;
    width: 100%;
    text-align: left;
  }

  .subscription-plan-list {
    display: block;
  }

  .subscription-plan-box {
    margin-bottom: 30px;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .subscription-pop-inner {
    padding-bottom: 0;
  }

  .careplan-title.document-title .dashboard-head-search {
    position: relative;
    top: 0;
    margin-right: 10px;
  }

  .careplan-title.document-title .dashboard-head-right {
    margin: 10px 0 0;
  }

  .care-plan-radios .clinic-administartor-box {
    width: 48%;
    margin-right: 2%;
  }

  .care-plan-radios .clinic-administartor-box:nth-child(3n) {
    margin-right: 2%;
  }

  .careplan-user img {
    margin-right: 10px;
    width: 50px;
  }

  .careplan-user-name h3 {
    font-size: 21px;
    font-weight: 400;
  }

  .careplan-user-name p {
    font-size: 12px;
  }

  .careplan-head-menu ul li {
    margin: 10px 13px;
    font-size: 14px;
  }

  .careplan-title h2 {
    font-size: 30px;
  }

  .careplan-title-btns .btn.blue-transparent {
    padding: 6px 10px;
    font-size: 14px;
    margin-right: 10px;
  }

  .careplan-title-btns .btn.blue-btn {
    font-size: 14px;
    padding: 8px 7px 8px 10px;
  }

  .careplan-title-btns .btn.blue-btn img {
    float: right;
    margin-left: 10px;
    margin-top: 3px;
    width: 15px;
  }

  .common-popup.pateint-detail-pop .common-popup-box {
    width: 90%;
  }

  .dashboard-head-search {
    float: right;
    margin-right: 0;
    position: absolute;
    right: 0;
    top: -50px;
  }

  .dashboard-head-search input {
    width: 200px;
  }

  .dashboard-inner-sec {
    padding: 30px 15px;
  }

  .patient-table .table td {
    font-size: 14px;
  }

  .patient-manual-entry {
    margin-bottom: 20px;
  }

  .care-plan-form {
    width: 100%;
    margin: 30px auto 30px;
  }

  .patient-pop-box .step-outter {
    width: 90%;
  }

  .step-wrapper.patient-success-pop .step-outter {
    max-width: 380px;
    width: 90%;
  }

  .common-popup.medication-pop .common-popup-box {
    width: 90%;
  }

  .common-popup.media-pop .common-popup-box {
    width: 90%;
  }

  .media-task-list ul li {
    width: 22%;
  }
}

@media screen and (max-width: 574px) {
  .front-left {
    padding: 30px;
  }

  .dashboard-inner-clinic .careplan-title-btns {
    width: 100%;
  }

  .dashboard-inner-clinic .careplan-title-btns .btn {
    width: 49%;
  }

  .media-list-title a {
    position: static;
  }

  .media-task-list ul {
    width: 100%;
  }

  .media-task-list ul li {
    width: 49%;
    margin-right: 2%;
  }

  .media-task-list ul li:nth-child(2n) {
    margin-right: 0%;
  }

  .media-img-close {
    width: 30px;
    height: 30px;
    font-size: 18px;
    padding-top: 3px;
    right: 10px;
    top: 10px;
  }

  .common-popup.media-full-pop .common-popup-box .media-name-date {
    bottom: 10px;
    left: 10px;
    padding: 10px 10px 15px;
    width: 200px;
    font-size: 20px;
  }

  .common-popup.media-full-pop .common-popup-box .media-name-date span {
    font-size: 14px;
  }

  .common-popup.media-full-pop .common-popup-box {
    width: 90%;
  }

  .update-patient-btns .btn.red-transparent {
    width: 30%;
    float: left;
  }

  .update-patient-btns .step-btn {
    width: 66%;
  }

  .patient-status-left {
    width: 100%;
    padding-right: 0;
    border-right: 0px solid #eaeaea;
    float: left;
  }

  .patient-status-right {
    display: inline-block;
    width: 100%;
  }

  .progress-dashboard {
    margin-top: 10px;
  }

  .summary-detail-sec {
    width: 100%;
  }

  .dashboard-inner-head .dashboard-head-btn {
    width: auto;
  }

  .care-plan-input input {
    font-size: 17px;
  }

  .dashboard-head-btn {
    float: right;
    width: 100%;
    margin-bottom: 10px;
  }

  .careplan-title.document-title .dashboard-head-search {
    width: 100%;
    margin-right: 0px;
  }

  .careplan-title.document-title .dashboard-head-search input {
    width: 100%;
  }

  .careplan-title.document-title .dashboard-head-btn a {
    width: 100%;
  }

  .careplan-title.document-title .dashboard-head-btn a img {
    margin-top: 0px;
    float: right;
  }

  .care-box-btn.add-care-btns .log-btn-grey.step-btn.add-patient-btn {
    width: 100%;
    margin-right: 0;
    margin-top: 20px;
    margin-bottom: 0;
  }

  .care-plan-box.add-careplan-box .log-btn-grey.step-btn.show-inactive-btn {
    width: 100%;
    margin-left: 0;
  }

  .care-plan-box.add-careplan-box .log-btn.step-btn.add-patient-btn {
    width: 100%;
  }

  .common-popup-box {
    width: 90%;
  }

  .care-plan-box.confirm-add-plan
    .care-box-btn
    .log-btn.step-btn.add-patient-btn {
    width: 100%;
  }

  .care-plan-box.confirm-add-plan .btn.orange-transparent.step-btn {
    width: 100%;
    margin-left: 0;
    margin-top: 5px;
  }

  .no-condition-sec {
    text-align: center;
    padding: 150px 20px;
  }

  .care-plan-radios .clinic-administartor-box {
    width: 100%;
    margin-right: 0%;
  }

  .care-plan-radios .clinic-administartor-box:nth-child(3n) {
    margin-right: 0%;
  }

  .clinic-detail-head .btn.sky-btn {
    float: right;
    width: 100%;
  }

  .profile-seting-area {
    width: 300px;
  }

  .subscription-table table.table {
    width: 600px;
  }

  .care-box-btn .log-btn.step-btn {
    width: 100%;
  }

  .care-box-btn .step-btn.done-inactive-btn {
    width: 100%;
    margin-left: 0;
    margin-top: 0;
  }

  .care-box-btn .log-btn-grey.step-btn {
    width: 100%;
    margin-left: 0;
    margin-top: 0;
  }

  .complete-condition-pop.delete-patient-pop .complete-condition-box {
    width: 90%;
  }

  .careplan-head-menu {
    display: inline-block;
    margin-top: 15px;
  }

  .careplan-user {
    display: inline-block;
    width: 160px;
    text-align: left;
  }

  .careplan-head {
    text-align: center;
  }

  .careplan-title-btns {
    float: right;
    width: 100%;
    margin-top: 10px;
  }

  .careplan-title-btns {
    width: 49%;
  }

  .careplan-title-btns .btn.blue-transparent {
    margin-right: 2%;
  }

  .careplan-title h2 {
    text-align: center;
  }

  .careplan-title {
    margin-top: 10px;
  }

  .log-header h1 {
    font-size: 28px;
  }

  .log-header h3 {
    font-size: 20px;
  }

  .dashboard-head-btn a {
    padding: 10px 10px 10px 15px;
    font-size: 12px;
  }

  .dashboard-head-filter {
    width: 175px;
    margin-right: 10px;
  }

  .dashboard-head-filter .select-box-list ul li {
    font-size: 12px;
  }

  .finished-btn-careplan {
    width: 100%;
    margin-top: 10px;
  }

  .finished-btn-careplan .btn.blue-btn {
    width: 100%;
  }

  .care-box-btn {
    padding-top: 0;
  }

  .careplan-head {
    overflow: hidden;
  }

  .subscription-pop-head {
    padding: 20px 70px 20px 30px;
  }

  .subscription-plan-price {
    font-size: 30px;
  }
}

@media screen and (max-width: 400px) {
  .careplan-title-btns {
    width: 100%;
  }

  .dashboard-inner-clinic .careplan-title-btns .btn {
    width: 100%;
    margin-right: 0;
  }

  .complete-condition-pop.downgrade-pop .complete-condition-box {
    width: 90%;
  }

  .careplan-title-btns .btn.blue-transparent {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .complete-condition-pop.coordinate-about-txt .complete-condition-box,
  .complete-condition-pop.contributor-about-txt .complete-condition-box,
  .complete-condition-pop.view-access-pop .complete-condition-box,
  .complete-condition-pop.care-plancode-pop .complete-condition-box {
    width: 90%;
  }
}

@media screen and (max-width: 370px) {
  .dashboard-head-btn {
    width: 100%;
  }

  .dashboard-head-filter {
    width: 100%;
    margin-right: 0px;
  }

  .dashboard-head-btn a {
    width: 100%;
  }

  .dashboard-head-btn a img {
    margin-top: 0;
    float: right;
  }

  .dashboard-head-search {
    float: right;
    margin-right: 0;
    position: relative;
    right: 0;
    top: 0;
    width: 100%;
    margin-top: 10px;
  }

  .dashboard-head-search input {
    width: 100%;
  }
}

.error-message {
  font-size: 11px;
  color: #f44336;
  margin-bottom: -5px;
  margin-top: 2px;
  display: block;
  padding-left: 10px;
  padding-top: 5px;
}

.error-wrapper {
  width: 100%;
  float: left;
}

/* 29/12/2020 */
textarea {
  border-radius: 10px !important;
  height: 60px !important;
}

input {
  border-radius: 10px !important;
}

.clinic-administartor-box:hover {
  cursor: pointer;
}

.PhoneInputInput {
  width: 100%;
  height: 44px;
  font-weight: 500;
  color: #333;
  font-size: 14px;
  padding: 12px;
  border: 0px;
  padding-left: 40px;
}

.custom-input {
  border-radius: 0px;
}

/* .input-div {
	background: #fff;
} */

.PhoneInputCountry {
  margin-left: 10px;
}

.form-control input {
  font-size: 14px;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  position: relative;
}

.google-open {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.google-image {
  position: absolute !important;
  right: 20px;
  bottom: 10px;
}

.google-open-google {
  position: absolute;
  right: 20px;
  bottom: 28px;
}

.book-appointment-form .react-datepicker-wrapper {
  width: 100%;
}

.common-popup-innersec .book-appointment-form .react-datepicker-wrapper {
  width: 100%;
}

img.input-google {
  z-index: 8;
}

.book-appointment-form .form-control {
  border-radius: 10px;
  height: 42px;
}

.react-datepicker__header {
  background-color: #fff;
  border-bottom: 0px;
}

.react-datepicker__month-container {
  width: 100%;
}

.react-datepicker {
  width: 100%;
  margin-top: 10px;
}

.react-datepicker__navigation--previous {
  border-right-color: none;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 50px;
}

img.input-google {
  right: 24px;
}

.y-sroll {
  max-height: 600px;
  overflow-y: scroll;
}

.css-1yc4zyy-DropdownHandleComponent svg {
  margin-right: -11px;
}

.form-control input {
  padding: 0px;
}

.input-div.field-medicince-compo {
  background: #fff;
  padding: 12px;
  height: 44px;
  font-weight: 500;
  color: #333;
  font-size: 14px;
  border: 0px;
  border-radius: 10px;
}

.input-div.field-medicince-compo ul {
  list-style: none;
  padding: 0px 15px;
  position: absolute;
  z-index: 99999;
  background: #fff;
  left: 0;
  width: 100%;
  overflow-y: auto;
}

.input-div.field-medicince-compo ul li {
  font-size: 12px;
  padding: 3px 0px;
  font-weight: 400;
}

.input-div.field-medicince-compo ul li ul {
  padding-left: 25px !important;
  overflow-y: inherit;
}

/* width */
.input-div.field-medicince-compo ul li ul::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.input-div.field-medicince-compo ul li ul::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.input-div.field-medicince-compo ul li ul::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.input-div.field-medicince-compo ul li ul::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#style-1::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

/* Set the appearance of the scrollbar thumb */
#style-1::-webkit-scrollbar-thumb {
  background-color: #c3c788;
  border-radius: 5px;
}

/* Set the appearance of the scrollbar thumb when hovered */
#style-1::-webkit-scrollbar-thumb:hover {
  background-color: #6bb88b;
}

.med-comp-ul1 li:hover {
  cursor: pointer;
}

.med-comp-ul1 li.toggle-sign::after {
  content: '\f101';
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 10px;
  font-size: 20px;
  transition: all 0.2s ease;
  transform: rotate(90deg);
  top: 2px;
  color: #00b4f3;
}

.input-div.field-medicince-compo:hover {
  cursor: pointer;
}

.med-comp-ul1 li.toggle-up::before {
  content: '\f102';
  transform: rotate(0deg);
  position: absolute;
  right: 10px;
  top: 0px;
  font: normal normal normal 14px/1 FontAwesome;
  color: #00b4f3;
  font-size: 20px;
}

.med-comp-ul2,
.med-comp-ul3 {
  position: relative !important;
  height: initial !important;
}

.input-div.field-medicince-compo ul li {
  position: relative;
}

.med-comp-ul1 {
  height: 100px !important;
}

.field-of-medicien .css-yk16xz-control {
  height: 44px;
  font-weight: 500;
  color: #333;
  font-size: 14px;
  background: white;
  border: 0px;
  border-radius: 10px;
  overflow: hidden;
}

.css-1pahdxg-control {
  border: 0px !important;
  box-shadow: unset !important;
  border-radius: 10px !important;
  min-height: 44px !important;
  font-size: 14px !important;
  color: #6c757d !important;
  font-weight: 500;
}

.css-1pahdxg-control:hover {
  border-color: #ffffff;
}

.css-1wa3eu0-placeholder {
  font-size: 14px !important;
  color: #6c757d !important;
}

.css-yt9ioa-option,
.css-1n7v3ny-option {
  font-family: Montserrat, sans-serif;
  color: #333 !important;
  font-size: 14px !important;
  font-weight: 500;
  cursor: pointer !important;
}

.field-of-medicien .css-4ljt47-MenuList {
  max-height: 225px !important;
}

.signup-profile .step-outter {
  overflow: inherit !important;
}

.signup-profile .three-steps,
.signup-profile .step-top {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.raduis-img {
  border-radius: 7px;
  overflow: hidden;
  position: relative;
}

.profile img {
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 10px;
}

.mobile-menu .account-menu img {
  width: auto;
}

td.pateint-list-wd img {
  width: 50px;
  height: 50px;
}

.clinic-img-wd img {
  width: 55px;
  border-radius: 50%;
  height: 55px;
  object-fit: cover;
}

.uplord-img img {
  width: 55px;
  border-radius: 50%;
  height: 55px;
  object-fit: cover;
}

.pateint-list-name img {
  width: 55px;
  border-radius: 10px;
  height: 55px;
  object-fit: cover;
}

.dashboard-head-btn a:hover {
  color: #fff;
}

.document-cursor:hover {
  cursor: pointer;
}

.prfile-setting-menu:hover {
  cursor: pointer;
}

.patient-table img:hover {
  cursor: pointer;
}

.dashboard-head-btn {
  cursor: pointer;
}

.dashboard-head-filter .select-box:hover {
  cursor: pointer;
}

a:hover {
  cursor: pointer;
}

a img:hover {
  cursor: pointer;
}

.team-mem-images.team-grup img {
  width: 50px;
  border-radius: 50px;
  height: 50px;
  object-fit: cover;
  overflow: hidden;
}

.team-mem-images.d-group img {
  width: 50px;
  border-radius: 50px;
  height: 50px;
  object-fit: cover;
  overflow: hidden;
}

.add-team-btn a:hover {
  color: #fff !important;
}

.close-new img {
  width: 30px;
  object-fit: contain;
}

.space-top {
  margin-top: 20px;
}

.select-custom {
  height: 44px !important;
  border-radius: 10px !important;
}

.react-dropdown-select.form-control.custom-input.select-custom.css-wmw4vi-ReactDropdownSelect.e1gzf2xs0 {
  height: 44px;
  border-radius: 10px;
}

.css-wmw4vi-ReactDropdownSelect:focus,
.css-wmw4vi-ReactDropdownSelect:focus-within {
  outline: 0;
  box-shadow: inherit;
}

.input-div .css-xo9ye0-DropDown {
  border: none;
}

[id^='react-select-2-group-*-heading'] {
  background: #eee;
  padding: 5px 10px;
}

.css-syji7d-Group .css-18ng2q5-group:first-child {
  background: #eee;
  padding: 5px 10px;
}

.css-syji7d-Group .css-syji7d-Group .css-18ng2q5-group:first-child {
  background: none;
  padding: 0px 10px;
}

.profile-seting-area .field-of-medicien .css-yk16xz-control {
  background: #f8f8f8;
  border-width: 0;
  font-size: 14px;
  font-weight: 400;
}

.profile-seting-area .css-1pahdxg-control {
  background: #f8f8f8;
  font-weight: 400;
}

td.pateint-name.pateint-block img {
  width: 50px;
  border-radius: 10px;
  height: 50px;
  object-fit: cover;
}

.input-div.cel-control.open
  .react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll
  span {
  position: relative;
  top: 5px;
  display: flex;
  align-items: center;
}

.input-div.cel-control.open .react-datepicker__month-read-view--selected-month,
.input-div.cel-control.open .react-datepicker__year-read-view--selected-year {
  color: initial;
  position: relative;
  top: 0px !important;
}

.cel-control .react-datepicker__tab-loop {
  z-index: 2;
  position: relative;
  top: -48px;
}

.custom-input-patient-add {
  width: 100%;
  float: left;
  position: relative;
  margin-top: 20px;
}

.focus-input-patient-add {
  font-size: 12px;
  color: #fa7a00;
  font-weight: 500;
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: 13px;
  left: 12px;
  transition: all ease 0.2s;
}

.focus-container-input-patient-add .react-dropdown-select-item {
  position: unset;
  opacity: 1;
  color: inherit;
}

.focus-container-input-patient-add
  .react-dropdown-select-content.react-dropdown-select-type-single.e1gn6jc30
  span {
  opacity: 1;
  font-weight: lighter;
  color: #333;
  font-size: 14px;
  z-index: 2;
  top: 12px !important;
}

.close-new img {
  right: -7px;
}

.administartor-box-name.text-width span {
  display: inline-block;
  width: 150px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.no-found a {
  border-bottom: 2px solid #121d55;
}

.no-found {
  font-size: 18px;
  color: #121d55;
}

.text-center.no-found {
  padding-top: 80px;
}

.hand-cursor {
  cursor: pointer;
}

.no-max-width {
  max-width: none !important;
}

/* 27 jan css */

.administartor-box-close .fa {
  position: absolute;
  top: 2px;
  right: 12px;
}

.set-popup-buttons {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.medicine-dropdown-container .react-dropdown-select:focus,
.medicine-dropdown-container .react-dropdown-select {
  box-shadow: unset !important;
  margin-bottom: 10px;
  padding: 10px;
}

.medicine-dropdown-container.focus-container-input-patient-add
  .react-dropdown-select-content.react-dropdown-select-type-single.e1gn6jc30
  span {
  color: #495057 !important;
}

.medicine-dropdown-container.focus-container-input-patient-add
  .react-dropdown-select-content.react-dropdown-select-type-single.e1gn6jc30
  input {
  color: #495057 !important;
}

.medicine-dropdown-container .css-1yc4zyy-DropdownHandleComponent svg {
  width: 21px !important;
  height: 30px !important;
}

.medicine-dropdown-container .react-dropdown-select-dropdown {
  z-index: 999999;
}

.medicine-dropdown-list-item {
  padding: 8px 12px;
  margin-bottom: 0px;
  cursor: pointer !important;
  display: block;
}

.medicine-dropdown-list-item:hover {
  background-color: #deebff;
  color: inherit;
  display: block;
  padding: 8px 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}

.font-cons-force-show {
  font: normal normal normal 14px/1 FontAwesome !important;
}

.show {
  display: block !important;
}

.mtl-new ul {
  width: 100%;
  overflow-x: scroll;
  display: flex;
  padding-bottom: 20px;
  -webkit-overflow-scrolling: touch;
}

/* width */
.mtl-new ul::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.mtl-new ul::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.mtl-new ul::-webkit-scrollbar-thumb {
  background: #e0e0e0;
}

/* Handle on hover */
.mtl-new ul::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.mtl-new ul li video {
  width: 100% !important;
  height: 150px;
  border-radius: 10px;
}

.mtl-new ul li img {
  height: 150px !important;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.media-task-list.mtl-new ul li {
  width: 200px !important;
  display: table;
  margin-right: 10px;
}

.sg-3 .CircularProgressbar .CircularProgressbar-path {
  stroke: #40cbe8;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.sg-3 .CircularProgressbar .CircularProgressbar-trail {
  stroke: #d2f5fb;
  stroke-linecap: round;
  stroke-width: 4px !important;
}

.sg-3 .CircularProgressbar .CircularProgressbar-text {
  fill: #f77a01;
}

.white-background {
  background: white;
}

.custm-loader.custom-loader-popup {
  position: absolute;
  top: 0;
}

.appointment-booking .css-wmw4vi-ReactDropdownSelect {
  border: none !important;
}

.appointment-booking .e1gn6jc30 span {
  color: #333 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.appointment-booking input::placeholder {
  color: #6c757d !important;
  font-weight: 500;
  font-size: 14px;
}

.appointment-booking
  .react-dropdown-select-dropdown.react-dropdown-select-dropdown-position-bottom.e1qjn9k90 {
  max-height: 150px;
}

.appointment-booking .css-1yc4zyy-DropdownHandleComponent svg {
  width: 26px;
  height: 20px;
  font-weight: 500;
  color: hsl(0, 0%, 80%);
}

.signup-booking-url .css-93ilmd-DropDown {
  width: 100%;
  top: 40px;
}

/* #495057 */

/*  */

.main-box {
  margin: 0 auto;
  width: 1000px;
  margin-top: 100px;
}

.tootlip-hover {
  background-color: #fa7a00;
  border-radius: 20px;
  padding: 18px 18px;
  width: 340px;
  box-shadow: 10px 10px 12px 0px rgba(245, 242, 245, 0.62);
  z-index: 999999;
}

.tootlip-hover h1 {
  font-size: 20px;
}

.Quick-d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.line-section {
  position: relative;
  margin: 16px 0;
}

.light-line {
  background: #bdbdbd57;
  width: 100%;
  height: 5px;
  border-radius: 50px;
}

.bold-line {
  background: #fff;
  width: 50%;
  height: 5px;
  border-radius: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.add-p p {
  color: #fff;
  font-weight: 100 !important;
}

.add-p h3 {
  font-size: 18px;
  font-weight: normal;
}

.next-bg {
  border-radius: 8px;
}

.next-bg a {
  color: #12cae6 !important;
}

.next-bg a:hover {
  color: #12cae6;
}

.main-relative {
  position: relative;
  width: 310px;
}

.triangle-up.top-tootlip {
  width: 0;
  height: 0;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-bottom: 20px solid #fa7a00;
  position: absolute;
  top: -11px;
  right: 25px;
  z-index: 999999;
}

.triangle-up.left-tootlip {
  width: 0;
  height: 0;
  border-top: 14px solid transparent;
  border-right: 14px solid #fa7a00;
  border-bottom: 20px solid transparent;
  position: absolute;
  left: -11px;
  bottom: 30px;
  z-index: 999999;
}

.triangle-up.center-tootlip {
  width: 0;
  height: 0;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-top: 20px solid #fa7a00;
  position: absolute;
  left: 40px;
  right: 0;
  bottom: -13px;
  margin: 0 auto;
  z-index: 999999;
}

/* .add-p-section.top-heading {
	padding: 32px 0px;
} */

.dashboard-head-btn {
  position: relative;
}

.top-tooltip-ab {
  position: absolute;
  right: 20px;
  z-index: 999;
  top: 54px;
}

.left-tooltip-ab {
  position: absolute;
  left: 90px;
  bottom: -10px;
  z-index: 99999999;
}

.td-relative {
  position: relative;
}

.top-sec-toottip {
  position: absolute;
  right: 30px;
  top: 87px;
  bottom: 0;
  z-index: 999999;
}

.care-box-btn {
  position: relative;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  /* Set a minimum height to occupy the full viewport height */
}

.pure-top {
  position: absolute;
  margin: 0 auto;
  left: 30%;
  top: -270%;
}

.text-heading-bg {
  background-color: #fa7a00;
  color: #fff;
}

.id-part h5 {
  font-size: 15px;
  color: #0f0f0f;
  letter-spacing: 0;
  line-height: 24px;
}

.id-part h4 {
  font-size: 22px;
  color: #00caea;
  letter-spacing: 0;
  line-height: 24px;
}

.id-part img {
  margin-bottom: 20px;
}

.id-part {
  padding: 210px 0;
}

.back-arrow a {
  font-size: 14px;
  color: #121d55;
}

.back-arrow a:hover {
  text-decoration: none;
}

.footer-custom {
  margin-left: 100px;
  text-transform: capitalize;
  padding: 15px 30px !important;
}

.footer-container {
  background: #f8f8f8;
  margin-top: 0 !important;
}

.footer-custom p:nth-last-child(2) {
  margin-right: 10px !important;
}

.pure-top-task-pop {
  top: -213% !important;
}

.new-patient-dob-picker .react-datepicker__month-select,
.new-patient-dob-picker .react-datepicker__year-select {
  border: none;
}

.no-activities-text {
  font-size: 17px;
  font-weight: 100;
  text-align: center;
}

.activity-image {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  object-fit: cover;
}

.document-datepicker .css-1vv85pa-DropDown {
  z-index: 999999;
}

.media-galary-no-data {
  font-size: 18px;
  font-weight: 500;
  color: #121d55;
}

.media-galary-no-data .small-text {
  font-weight: 200;
}

.add-patient-pop-background {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: unset;
}

.compliance-graph {
  color: #fa7a00;
}

.compliance-graph .percentage {
  font-size: 45px;
  font-weight: 600;
}

.compliance-graph .CircularProgressbar .CircularProgressbar-path {
  stroke: #fa7a00;
}

.add-more-condition {
  color: #121d55;
  text-decoration: underline;
}

/*  */

.capitalize {
  text-transform: capitalize;
}

.left-0 {
  left: 0 !important;
}

.upload-document .react-datepicker-wrapper {
  display: block;
}

.upload-document-patient .patient-pop-box .custom-input {
  border-radius: 10px;
}

.sg-3 .CircularProgressbar .CircularProgressbar-text {
  fill: #f77a01;
}

.password-rest-eye-hide {
  position: absolute;
  right: 12px;
  top: 8px;
  z-index: 2;
}

.patient-row {
  cursor: pointer;
}

.patient-row :hover {
  cursor: pointer;
  background-color: #eee;
}

.clinic-input-box {
  padding: 15px;
  background: #f8f8f8;
  box-shadow: unset;
  margin-right: 2%;
  border-radius: 10px;
  margin-top: 15px;
  display: inline-block;
  width: 100%;
  position: relative;
}

.care-plan-input-outer {
  padding-left: 30px;
}

.care-plan-input-outer .care-plan-input input {
  margin-top: 10px !important;
  background: #fff !important;
}

.redirect-to-clnic-details a {
  text-decoration: none;
  color: white;
}

.new-condition-input input:disabled {
  background: #e3e3e3 !important;
  cursor: not-allowed;
}

.dropdonload-loading {
  height: 20px;
  position: absolute;
  top: 8px;
  right: 30px;
}

/* additional css */

.due-date-show {
  transition: all 0.31s ease;
  height: 68px;
  /* overflow: hidden; */
}

.due-date-hide {
  height: 0px;
  overflow: hidden;
  transition: all 0.31s ease;
}

.care-plan-margin {
  margin-top: 10px;
  padding-bottom: 0px;
}

.progress-title2 {
  font-size: 25px !important;
}

.progress-title2 h2 {
  font-size: 25px !important;
}

.inner-clinic2 {
  margin-top: 20px;
  margin-bottom: 10px;
  min-height: calc(100vh - 30px);
}

.progress-dashboard2 {
  margin-top: 15px;
}

.status-left2 ul {
  margin-top: 9px;
}

.progress-conditions2 {
  margin-top: 0px;
}

.right-activity-action2 {
  position: absolute;
  top: 0;
}

.small-box2 {
  margin-top: 30px;
}

.dashboard-inner-head2 {
  min-height: calc(100vh - 100px);
}

/* additional css  end*/

.fa-pencil {
  font-size: 22px !important;
  color: #12cae6;
}

.fa-pencil:hover {
  color: #121d55;
  font-size: 22px !important;
}

.w-60 {
  width: 60px;
}

.popup-common-class {
  background: rgba(255, 255, 255, 0.9) !important;
  /* box-shadow: 5px 5px 10px rgb(0 0 0 / 0.16) !important; */
  /* box-shadow: 0 0 15px 10px rgba(134, 134, 134, 0.5) !important; */
  transition: all ease 0.3s;
}

.modal {
  background: rgba(255, 255, 255, 0.82) !important;
}

.modal-content {
  border-radius: 20px !important;
  border: none !important;
}

.css-wmw4vi-ReactDropdownSelect {
  padding: 0 !important;
  align-items: center !important;
}

.modal-backdrop {
  background-color: #fff !important;
}

/* Medium screens */

/* Large screens */

.signupbackground {
  background: url(/assets/img/blurbg.png) center center no-repeat !important;
}

.signupbackground-new {
  background: url(/assets/img/blurbg-new.png) center center no-repeat !important;
}

.select-from-predefined-library {
  font-size: 14px !important;
  width: 100%;
  text-align: center;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.patient-header-pic {
  width: 78px !important;
  height: 78px !important;
}

.width {
  width: 350px;
  transition: all ease 0.3s;
  left: -400px;
  z-index: 1;
}

.width.show {
  width: 350px;
  left: 100px;
}

.top-sec-toottip:hover,
.main-relative:hover {
  border-radius: 20px;
}

.top-sec-toottip:hover,
.top-sec-toottip .tootlip-hover:hover,
.top-sec-toottip .add-p-section:hover,
.top-sec-toottip .Quick-d-flex:hover,
.top-sec-toottip .Quick-d-flex h1:hover {
  background: #fa7a00 !important;
}

.top-sec-toottip .tootlip-hover .add-p:hover,
.top-sec-toottip .tootlip-hover .add-p p:hover,
.top-sec-toottip .tootlip-hover .add-p h3:hover {
  background: #fa7a00 !important;
}

.appo-list-item {
  padding: 7px 10px;
}

.clinic-appoint-url .e1gzf2xs0 {
  height: 100% !important;
  padding: 11px 10px !important;
}

.clinic-appoint-url .dr-name-url {
  position: inherit !important;
  word-break: break-all;
}

#appointment-booking-url-placeholder {
  color: #6c757d !important;
}

.profile-detail-area .e1gzf2xs0 {
  background: #f8f8f8;
  border-width: 0;
  font-size: 14px;
  font-weight: 400;
}

.clinic-appoint-url .e1qjn9k90 {
  z-index: 999999;
}

.no-data-progress {
  width: 100%;
  float: left;
  height: calc(100vh - 500px);
  border: 1px solid #ddd;
  margin-top: 20px;
  position: relative;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 10px #00000029;
}

.no-data-progress b {
  position: absolute;
  left: 15px;
  top: 10px;
  color: #121d55;
}

.no-data-progress a {
  max-width: 360px;
  text-align: center;
  color: #121d55;
  font-weight: 500;
  text-decoration: underline;
}

.no-data-progress-single {
  width: 100%;
  float: left;
  min-height: 100%;
  border: 1px solid #ddd;
  margin-top: 15px;
  position: relative;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 10px #00000029;
}

.no-data-progress-single b {
  position: absolute;
  top: 15px;
  left: 15px;
  color: #121d55;
}

.no-data-progress-single a {
  max-width: 360px;
  text-align: center;
  color: #121d55;
  font-weight: 500;
  text-decoration: underline;
}

.text-decoration-none-underline {
  text-decoration: underline !important;
}

.health-measurements-tooltip {
  top: -158% !important;
}

.trackprogress-tooltip {
  top: -364% !important;
  right: 60px;
}

.fade:not(.show) {
  z-index: -1;
  overflow: hidden;
}

.animate-box2 {
  top: 300px;
  left: 240px;
  transition: all ease 0.3s;
  opacity: 1;
  position: absolute;
}

.animate-box {
  top: 0px;
  transition: all ease 0.3s;
  opacity: 1;
  position: relative;
}

.fade:not(.show) .animate-box {
  top: -50px;
  transition: all ease 0.3s;
  opacity: 0;
}

.team-mem-images.careplan img:last-child {
  margin-left: 0px !important;
}

.per-text {
  position: relative;
}

.per-text::before {
  content: 'per';
  position: absolute;
  left: -9px;
  font-size: 12px;
  height: 42px;
  display: flex;
  align-items: center;
  color: #495057;
}

.get-med-loading {
  top: 43px;
}

.focus-container-input-patient-add
  .react-dropdown-select-content.react-dropdown-select-type-single.e1gn6jc30
  span {
  z-index: 1;
}

.add-new-patinet-dropdown .container-shadow {
  position: absolute;
  z-index: 99;
  width: 100%;
  max-height: 150px;
  overflow: scroll;
}

.no-z-index {
  z-index: 0;
}

.tooltip-shadow {
  -webkit-box-shadow: 3px 15px 23px -8px rgba(143, 143, 143, 1);
  -moz-box-shadow: 3px 15px 23px -8px rgba(143, 143, 143, 1);
  box-shadow: 3px 15px 23px -8px rgba(143, 143, 143, 1);
}

.care-code-input {
  width: 100%;
  display: inline-block;
  margin-right: 2%;
  font-size: 22px;
  text-transform: uppercase;
  text-align: center;
  margin-right: 0;
}

.care-code-input input {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 10px !important;
  border-width: 0;
}

.no-documents a {
  max-width: 360px;
  text-align: center;
  color: #121d55;
  font-weight: 500;
  text-decoration: underline;
}

.care-code-font {
  font-size: 24px !important;
  font-weight: 400 !important;
  display: flex !important;
  align-items: flex-end !important;
  height: 100% !important;
  line-height: 100%;
}

.care-code-input input {
  width: 45px !important;
  float: right;
  border-radius: 5px !important;
}

.progress-right .small-box .progress-alerts {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

.progress-right .small-box .progress-alerts::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

#scrollableDiv {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

#scrollableDiv::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.highlight-task {
  background-color: #eee;
}

.administartor-radio-1 {
  position: absolute !important;
  left: auto !important;
  top: 26px !important;
  right: -5px !important;
}

.subscription-plan-box .subscription-plan-description {
  min-height: 215px;
}

.subscription-annual-plan-save{ 
  color: #0082C8;
  font-weight: 600;
  font-size: 14px;
}

.annual-price {
  font-weight: 600;
  font-size: 14px
}

.subscription-annual-plan {
  margin-top: 2px;
  color:#5B5B5B; 
  border-radius: 5px;
  padding: 5px 15px;
  background: #E9F0FF;

}

.Select-input > input {
  width: 100% !important;
}

.Select-placeholder {
  line-height: 50px !important;
  padding-left: 0 !important;
}

.Select-input {
  height: 100% !important;
  width: 100% !important;
}

.Select-menu-outer .Select-menu {
  max-height: 183px !important;
  overflow-y: auto;
}

.Select-arrow-zone {
  opacity: 1 !important;
  z-index: 1 !important;
  top: 22px !important;
  transition: all 0.2s ease;
  right: 5px !important;
  left: auto !important;
}

.Select-clear-zone {
  opacity: 1 !important;
  z-index: 1 !important;
  top: 12px !important;
  transition: all 0.2s ease;
  right: 30px !important;
  left: auto !important;
}

.input-div.open .Select-arrow-zone {
  top: 20px !important;
  opacity: 1 !important;
}

.input-div.open .Select-clear-zone {
  top: 12px !important;
  opacity: 1 !important;
}

.Select-control .Select-clear-zone .Select-clear {
  font-size: 25px !important;
  top: -8px !important;
}

.input-div .Select-control .Select-input {
  height: 100% !important;
  padding-top: 10px !important;
  padding-left: 0px !important;
}

.Select-multi-value-wrapper {
  width: 100% !important;
}

.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.has-value.is-pseudo-focused.Select--single
  > .Select-control
  .Select-value
  .Select-value-label {
  padding-top: 13px !important;
  padding-left: 0px !important;
  font-size: 14px !important;
  width: 80% !important;
  font-weight: lighter !important;
  margin-left: -10px;
}

.Select-placeholder,
.Select--single > .Select-control .Select-value {
  bottom: 0;
  color: #aaa;
  left: 0;
  line-height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  right: 0;
  top: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.input-div-1 {
  width: 100%;
  float: left;
  position: relative;
  margin-top: 20px;
}

.input-div-1 span {
  font-size: 12px;
  color: #fa7a00;
  font-weight: 500;
  opacity: 1;
  position: absolute;
  z-index: 1;
  top: -6px;
  left: 12px;
  transition: all ease 0.2s;
}

/* .input-div-1.open span {
  opacity: 1;
  z-index: 1;
  top: -8px;
  transition: all ease 0.2s;
} */

.reset-email-block {
  display: block !important;
}

.Select.Select--single.is-focused {
  color: red;
}

.Select-control .Select-input input {
  position: relative;
  top: 8px;
  left: -5px;
}

.Select-control .Select-placeholder {
  color: #6c757d;
  opacity: 1;
  font-weight: lighter;
  font-size: 13px;
}

.fom-label {
  font-size: 12px;
  color: #fa7a00;
  font-weight: 500;
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: 13px;
  left: 12px;
  transition: all ease 0.2s;
}

.fom-label.fom-label-1 {
  position: absolute;
  z-index: 9999;
  top: -7px;
  opacity: 1;
  left: 12px;
  transition: all ease 0.2s;
}

.is-focused:not(.is-open) > .Select-control {
  border-color: #fff !important;
  box-shadow: unset !important;
}

.Select-control {
  border-color: #fff !important;
  box-shadow: unset !important;
  height: 40px !important;
}

.is-open > .Select-control .Select-arrow-zone {
  top: 16px !important;
}

.profile-name-edit .Select-menu-outer {
  z-index: 99;
}

.profile-name-edit .Select-control {
  background: #f8f8f8;
  border-radius: 8px;
}

.profile-name-edit .Select-clear-zone {
  top: 0px !important;
  right: 5px !important;
}

.profile-name-edit .Select-arrow {
  height: 10px;
  width: 10px;
}

.profile-name-edit .Select-arrow-zone {
  top: 2px !important;
}

.profile-name-edit .is-open .Select-arrow-zone span {
  top: -20px !important;
}

.profile-name-edit .Select-control .Select-input input {
  position: relative;
  top: 0px;
  left: -5px;
  font-size: 14px;
}

.profile-name-edit .Select .is-open .Select-arrow-zone {
  position: relative;
  top: -20px !important;
}

.profile-name-edit
  .has-value.Select--single
  > .Select-control
  .Select-value
  .Select-value-label,
.has-value.is-pseudo-focused.Select--single
  > .Select-control
  .Select-value
  .Select-value-label {
  margin-left: -3px;
}

.profile-name-edit .is-clearable .Select-control .Select-placeholder {
  top: -5px;
  left: 10px;
}

.Select-control .Select-input input {
  z-index: -1 !important;
}

.versionText {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.versionText p {
  font-size: 16px;
  color: #5f5e5e;
  font-weight: 700;
}

/* Heath Notes CSS */
.KMGxMA-custom {
  background: white;
  border-radius: 2px;
  padding: 8px 16px;
  /* margin-top: 8px;
  margin-left: 8px; */
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 3px 1px -2px rgb(0 0 0 / 12%);
  padding-right: 24px;
}

.annotaion-delete {
  cursor: pointer;
  right: 0;
}

.boder-top-gray {
  border-top: 1px solid #dee2e6;
}

.add-heath-notes {
  background: #121d55 !important;
  color: #fff !important;
  padding: 13px 15px 13px 20px !important;
  display: inline-block;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 100;
  border-radius: 10px;
  text-decoration: none;
}

.heath-note {
  background: #f8f8f8 !important;
  color: #121d55 !important;
  padding: 13px 15px 13px 20px !important;
  display: inline-block;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 100;
  border-radius: 10px;
  text-decoration: none;
}

.jzUToB {
  z-index: 2;
  margin-top: 0px;
}

.hObiiS {
  border: solid 3px white;
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: 0 0 0 1px rgb(255 0 0 / 30%), 0 0 0 2px rgb(255 0 0 / 20%),
    0 5px 4px rgb(255 0 0 / 40%) !important;
  height: 16px;
  position: absolute;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  width: 16px;
}

/* css image page */
.sc-iwsKbI.bNCCdn {
  padding: 17px;
  border-radius: 8px;
  box-shadow: 0px 1px 10px #00000029;
}

.mt-30 {
  margin-top: 30px;
}

.form-group.head-summary label,
.form-group.attachFile,
.head-summary label {
  font-size: 14px;
  color: orange;
}

.form-group.head-summary textarea,
.form-group.head-summary input {
  border: 0px !important;
  font-size: 15px !important;
  font-weight: 400 !important;
}

.form-group.attachFile input {
  padding: 0px;
  padding-left: 5px;
  border: 0px;
  font-size: 12px;
}

.notehead p {
  font-size: 14px;
  color: #000000ad;
}

.attachmentsName span {
  font-size: 14px;
  margin-right: 25px;
  color: #121d55c7;
  font-weight: 100 !important;
}

.form-group.attachFile {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.attachmentInput label span {
  font-size: 27px;
  vertical-align: middle;
  padding-left: 5px;
}

.attachmentInput label {
  padding: 2px 10px;
  background: #121d55;
  display: table;
  color: #fff;
  border-radius: 12px;
  margin-left: 13px;
  font-size: 12px;
  cursor: pointer;
}

.attachmentInput input[type='file'] {
  display: none;
}

.dates-sect {
  display: block;
  float: left;
  width: 100%;
}

.attachmentsName span a {
  margin-left: 5px;
}

.dates-sect a.care-plantable-edit {
  display: inline-block;
  margin-right: 15px;
  margin-left: 0px !important;
  margin-bottom: 15px;
}

.heath-notes-form-loading {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  text-align: center;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding-top: 0;
}

.health-notes-text-care-plan {
  color: #12cae6 !important;
  text-decoration: underline;
  cursor: pointer;
}

.jzUToB {
  background: white;
  border-radius: 2px;
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 3px 1px -2px rgb(0 0 0 / 12%);
  margin-top: 16px;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  -webkit-animation: kncfuS 0.31s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation: kncfuS 0.31s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  overflow: hidden;
}

.jzUToB {
  z-index: 2;
  margin-top: 0px;
}

.SWGUp {
  padding: 8px 16px;
}

.SWGUp textarea {
  border: 0;
  font-size: 14px;
  margin: 6px 0;
  min-height: 60px;
  outline: 0;
}

.iTFQCX {
  background: whitesmoke;
  border: 0;
  box-sizing: border-box;
  color: #363636;
  cursor: pointer;
  font-size: 1rem;
  margin: 0;
  outline: 0;
  padding: 8px 16px;
  text-align: center;
  text-shadow: 0 1px 0 rgb(0 0 0 / 10%);
  width: 100%;
  -webkit-transition: background 0.21s ease-in-out;
  transition: background 0.21s ease-in-out;
}

/* css image page */
.video-blue-overlay {
  position: relative;
}

.video-blue-overlay::before {
  content: '';
  width: 100%;
  position: absolute;
  top: 0;
  border: 0;
  left: 0;
  right: 0;
  height: 100%;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(30px);
  z-index: 9;
}

.v-chat-modal .vchat-head h2 {
  margin-bottom: 0px;
}

.v-chat-modal .participant h3 {
  font-size: 24px;
  margin-bottom: 15px;
}

.v-chat-modal .participant.vchat-participant-wrp {
  background: #000;
}

.v-chat-modal .participant.vchat-participant-wrp video {
  width: 100%;
  height: 80vh;
  /* object-fit: contain; */
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: fliph;
  /*IE*/
  filter: fliph;
  /*IE*/
}

.v-chat-modal .common-popup-innersec {
  min-height: 80vh;
}

.con-msg {
  padding: 25px;
}

.v-chat-modal .common-pop-close {
  position: absolute;
  right: 15px;
  cursor: pointer;
  top: 20px;
  background: #fff;
  border-radius: 50px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  z-index: 999;
}

.remote-participants {
  position: absolute;
  top: 35px;
  right: 10px;
  z-index: 99999;
}

.remote-participants h3 {
  color: #fff;
  font-size: 22px;
}

.remote-participants h4 {
  color: #fff;
  font-size: 16px;
  text-align: right;
  width: 100%;
  margin-bottom: 12.5px;
}

.remote-participants video {
  width: 100% !important;
  height: 150px !important;
}

.remote-participants .participant.vchat-participant-wrp {
  padding: 5px;
  width: 250px;
  text-align: right;
  background: rgb(38 38 38);
  border-radius: 2px;
  position: relative;
  margin-bottom: 15px;
}

.v-chat-modal .local-participant h4 {
  position: absolute;
  color: #fff;
  top: 20px;
  left: 25px;
  font-size: 20px;
}

.vchat-control-icons {
  background: #fff;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vchat-control-icons svg {
  height: 45px;
  margin: 0px 15px;
  font-size: 22px;
  cursor: pointer;
}

.vchat-remote-control {
  position: absolute;
  top: 10px;
  background: #fff;
  right: 10px;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  z-index: 99;
}

.video-blue-overlay h6 {
  color: #fff;
  position: absolute;
  z-index: 99;
  width: 100%;
  text-align: center;
  top: 43%;
}

.no-scroll {
  overflow: hidden !important;
}

.conntenting-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.local-participant .vchat-remote-control {
  left: 50%;
  bottom: 30px;
  top: unset;
}

.activity-detail.notifications {
  padding-top: 23px;
}

.badges-brand {
  position: absolute;
  right: 28px;
  top: -1px;
  background: red;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: white;
  font-size: 12px;
  font-weight: 600;
}

.participants-wpr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1650px;
  margin: auto;
  margin-top: 28px;
  margin-bottom: 15px;
  position: relative;
}

.participants-wpr h3 {
  font-size: 25px;
  color: black;
}

.participants-wpr h4 {
  font-size: 25px;
  color: red;
}

.video-chat-wpr {
  max-width: 1650px;
  margin: auto;
  background: transparent;
  border-radius: 13px;
}

.participant.vchat-participant-wrp video {
  width: 100%;
  /* object-fit: cover; */
  border-radius: 15px 15px 0px 0px;
  height: 80vh;
}

.video-chat-wpr {
  position: relative;
}

.video-chat-wpr h4 {
  color: white;
  position: absolute;
  background: #868080;
  border-radius: 5px;
  top: 20px;
  left: 26px;
  padding: 5px 10px;
  font-size: 15px;
}

.full-heigh {
  height: 100vh;
}

.common-popup-innersec.conntecting {
  background: #f8f8f8;
  padding: 20px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remote-participants .video-chat-wpr h4 {
  display: none;
}

.toast-container-custom {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 300px;
  z-index: 999999;
  background: #fff;
  box-shadow: 0px 1px 10px #00000029;
  padding: 15px;
}

.toast-container-custom p {
  font-size: 14px;
  margin-bottom: 5px;
  color: #121d55;
  font-weight: 500;
}

.toast-container-custom .body {
  font-weight: 400;
}

.toast-container-custom .close-noti {
  position: absolute;
  top: -5px;
  right: 0px;
  width: 17px;
  height: 17px;
  object-fit: contain;
  cursor: pointer;
}

.video-chat-wrp {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: calc(100vh - 58px);
  margin-top: 58px;
  padding: 0px 25px;
}

.video-chat-wrp .room {
  background: #000;
  width: 100%;
  border-radius: 0px;
  margin-right: 15px;
  position: relative;
}

.video-chat-wrp .text-chat {
  height: 80vh;
  overflow: scroll;
  width: 275px;
  position: relative;
}

.participants-wpr {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  margin-top: 0px;
  padding: 10px 25px;
  background: #f8f8f8;
  z-index: 999;
  width: 100%;
  margin-bottom: 0px;
}

.bottom-wrap {
  position: fixed;
  width: 100%;
  bottom: 0;
  right: 0;
}

.bottom-wrap button {
  position: absolute;
  right: 0;
  top: 0;
}

.message-wrp {
  height: calc(100vh - 60px);
  overflow-y: auto;
  padding-bottom: 15px;
}

.bottom-wrap {
  bottom: 15px;
  width: 260px;
}

.bottom-wrap input {
  border-radius: 2px !important;
}

.condition-indeline-edit-wrp input {
  width: 76%;
}

.condition-indeline-edit-wrp {
  display: flex;
  margin: 4px 0px;
}

.remote-participants .vchat-control-icons {
  background: #fff;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 15px;
  width: calc(100% - 292px);
  margin-left: 25px;
  z-index: 9;
}

button.green-border.btn {
  background: #4fc931;
  margin-left: 10px;
  border-radius: 10px;
  /* width: 60px; */
  border: 1px solid #4fc931;
  color: white;
}

button.transparent-gray-border.btn {
  background: #ef0000;
  margin-left: 10px;
  border-radius: 10px;
  /* width: 60px; */
  border: 1px solid #dee2e6;
  color: white;
}

.clinic-profile-signup .Select-option-group-label {
  font-size: 12px;
  padding-bottom: 10px;
  padding-left: 10px;
  color: #000;
}

.clinic-profile-signup .Select-option {
  font-size: 12px;
  color: #000;
}

.clinic-profile-signup .Select-option.is-focused {
  background-color: transparent !important;
}

.clinic-profile-signup .Select-control .Select-placeholder {
  color: #6c757d;
}

.btn.blue-light-transparent,
.btn.blue-light-transparent:hover {
  border: 2px solid #12cae6 !important;
  border-radius: 10px;
  padding: 8px 15px;
  color: #12cae6 !important;
}

.pull-right {
  float: right !important;
}

.careplan-user-name.d-flex {
  height: 78px;
}

.no-health-point-sec p {
  color: #121d55;
  font-weight: 500;
  text-decoration: underline;
  height: 47px;
  display: flex;
  align-items: center;
}

.clinic-detail-inner p a {
  word-break: break-all;
}

.crisp-client .cc-kv6t .cc-1xry .cc-ge4v .cc-bdff .cc-x32x {
  margin-top: 60px !important;
}

.dashboard-head-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-inner-head.dashboard-head-flex h2 {
  width: 200px;
}

.mt-20 {
  margin-top: 20px;
}

.document-upload .step-outter {
  overflow: inherit !important;
  display: flex !important;
}

.followup-booking {
  overflow: inherit !important;
}

.patient-table-min-height .table-responsive {
  min-height: 400px;
}

.table-responsive {
  overflow-x: hidden !important;
}

.stepsGraph-inline {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  top: -20px;
}

.stepsGraph-inline .steps-graph-new-height {
  height: 50px !important;
  width: 100px !important;
  margin: 0;
}

.stepsGraph-inline .steps-goal h3 {
  font-size: 20px;
  margin-bottom: 0px;
}

.stepsGraph-inline .steps-goal {
  text-align: center;
  padding-top: 45px;
  margin: 0 auto;
}

.stepsGraph-inline .steps-goal span {
  font-size: 18px;
}

.weight-orange small {
  font-size: 22px;
  font-weight: 500;
}

/* ------------ admin --------------*/

.admin_header {
  color: #ef0000;
  font-weight: 600;
  margin-top: 1.5rem;
  font-size: 26px;
}

.admin-logout-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
}

.admin-big-bg {
  position: fixed;
  right: 500px;
  bottom: -10px;
  height: 90vh;
  object-fit: contain;
}

.login-header {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.login-header p {
  color: red;
}

.select-auto-height {
  height: auto !important;
}

.react-dropdown-select .react-dropdown-select-content {
  flex-wrap: initial;
  overflow: hidden;
}

.react-dropdown-select .react-dropdown-select-content span {
  /* position: relative !important; */
  /* top: auto !important; */
  left: auto !important;
  margin-left: 7px;
  white-space: nowrap;
}

.react-dropdown-select[aria-expanded='false'] .react-dropdown-select-content {
  justify-content: flex-start;
}

.google-input-auto-select input {
  padding-right: 50px;
}

/* .custom-file-upload span {
  overflow: hidden;
  width: 93%;
  text-overflow: ellipsis;
  display: inline-flex;
} */

.custom-file-upload-over .doc-name {
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  display: block;
  padding-right: 40px;
}

.custom-file-upload-ex {
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  display: block;
  padding-right: 40px;
}

@media (min-width: 1440px) and (max-width: 1680px) {
  .admin-big-bg {
    right: 300px;
  }
}

@media (min-width: 1060px) and (max-width: 1440px) {
  .admin-big-bg {
    right: 150px;
  }
}

@media (min-width: 320px) and (max-width: 1080px) {
  .admin-big-bg {
    display: none;
  }

  .front-left {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .front-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    float: unset;
    background: #f8f8f8;
    min-height: 100vh;
  }

  .admin-big-bg {
    display: none;
  }
}

.notification-sidebar-elem .collapsing {
  position: fixed;
}

.medicine-dropdown-container.focus-container-input-patient-add
  .react-dropdown-select-content.react-dropdown-select-type-single.e1gn6jc30
  span {
  color: #000 !important;
  font-weight: 300 !important;
}

.leftbar-menu .leftbar-bottom ul {
  list-style: none;
}

.spreater {
  text-align: center;
  margin: 10px 0px 0 0;
}

label.ml-3.health-engine {
  color: #495057;
}
.step-btn[disabled] {
  cursor: no-drop;
  background: #eee9e9;
}

.sticky-header-container{
  height: calc( 100vh - 165px );
  overflow-y: auto;
}

.sticky-header-container::-webkit-scrollbar{
  width: 8px;
}

.sticky-header-container .table-responsive {
  overflow-y: auto;
}

.sticky-header-container::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #121d55;
}


.sticky-header-table th{
  position: sticky;
  top: -5px;
  background: white;
}

.admin-tag {
  text-align: center;
  margin-top: 30px;
  position: relative;
  font-weight: bold;
  color: #121d55;
}

.disabled-item {
  color: #c0bebe!important;
  cursor: not-allowed;
}

.template-setting-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.is-invalid {
  border: 1px solid #fa7a00!important;
  border-radius: 5px;
}

.border-tag {
  border: 2px solid #121d55;
  border-radius: 10px;
}