.card-main-div {
  cursor: default;
}

.admin-main-inner-sec {
  margin-left: 130px !important;
}

.admin-inner-sec {
  width: 100%;
  margin-left: 0px;
  max-width: 100%;
  padding: 30px 15px;
}

.admin-screen {
  display: inline-block;
  width: 100%;
  background: #f8f8f8;
}

.admin-inner-head2 h2 {
  font-weight: normal;
  font-size: 26px;
  color: #121d55 !important;
}

.badge-list {
  border-radius: 10px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 20vh;
}

.card-row {
  justify-content: start;
  display: flex;
  flex-wrap: wrap;
}

.care-plan-template-list .admin-inner-sec,
.badge-list-inner-section .admin-inner-sec {
  padding: 40px;
  margin-top: 30px;
  background: #fff;
  box-shadow: 0px 1px 10px #00000029;
  min-height: 90vh;
}

.care-plan-template-list .admin-inner-sec {
  width: 98%;
}

.badge-list-inner-section .admin-inner-sec {
  width: 100%;
}

.badge-card,
.care-plan-card {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgb(0, 0, 0, 0.4);
  padding: 14px;
  cursor: pointer;
}

.badge-card .badge-icon {
  background-color: black;
  text-align: center;
  width: fit-content;
  height: 50px;
}

.badge-card .badge-icon img {
  min-height: 50px;
  max-width: 50px;
  border-radius: 5px;
}

.badge-details {
  margin-left: 14px;
}

.badge-details p {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #121d55;
}

.badge-details .badge-details-name-info img {
  height: 12px;
  width: 12px;
  /* z-index: 999; */
}

.badge-details .badge-details-name-info p {
  color: #121d55;
}

.badge-details .badge-points p,
.care-plan-card .task-count span {
  color: #fa7a00;
  text-transform: capitalize;
}

.badge-details .badge-points .points-count input,
.plans-count {
  background-color: #f8f8f8;
  padding: 6px 8px;
  border-radius: 10px;
  width: 80%;
  border: none;
}

.invalid {
  border: 1px solid red !important;
}

.valid {
  border: 1px solid green !important;
}

.badge-points .plans-count span,
.badge-points .points-count span {
  font-size: 14px;
  font-weight: 500;
  color: #121d55;
}

.badge-points .points-count input::placeholder {
  color: #121d55;
}

.admin-head-btn div {
  background: #121d55;
  color: #fff !important;
  padding: 10px 15px 10px 20px;
  display: inline-block;
  font-weight: 100;
  border-radius: 10px;
  text-decoration: none !important;
  cursor: pointer;
}

.care-plan-card .task-count p {
  font-size: 35px;
  color: #121d55;
  margin-bottom: 0 !important;
  font-weight: 500;
  text-transform: capitalize;
}

.care-tamplates-list {
  text-decoration: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}


.admin-template-card-container {
  position: relative;
}

.admin-template-delete {
  position: absolute;
  top: 5px;
  right: 10px;
  /* color: red; */
}
/* .row {
        margin: auto 0 !important;
    } */

::-webkit-scrollbar {
  width: 0px;
}

.main-badge-card {
  padding: 14px 0px;
  margin-right: 24px;
}

/* =========================== */

@media only screen and (max-width: 1920px) and (min-width: 1201px),
(max-width: 1200px) and (min-width: 1025px),
(max-width: 1024px) and (min-width: 769px),
(max-width: 768px) and (min-width: 481px),
(max-width: 480px) and (min-width: 320px) {
  .admin-main-inner-sec {
    margin-left: 0;
    margin-top: 40px;
  }

  .next-arrow {
    display: none;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 481px) {
  .badge-list-inner-section .admin-inner-sec {
    min-height: 45vh !important;
  }

  .admin-main-inner-sec {
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 480px) and (min-width: 320px) {
  .badge-card {
    padding: 14px;
  }

  .badge-card .badge-icon img {
    height: 50px;
    width: 50px;
    border-radius: 5px;
  }

  .badge-list-inner-section .admin-inner-sec {
    min-height: 52vh !important;
  }

  .care-plan-header {
    flex-direction: column;
  }

  .admin-head-btn {
    margin-bottom: 10px;
  }

  .admin-main-inner-sec {
    margin-left: 0px !important;
  }
}